@import '../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
  margin-bottom: 41px;
  margin-top: 46px;
}

/* ================ Typography ================ */

/* Title of the modal */
.modalTitle {
  composes: marketplaceModalTitleStyles from global;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 40px;
  letter-spacing: -0.9px;
  color: #4A4A4A;
}

/* Paragraph for the Modal */
.modalMessage {
  composes: marketplaceModalParagraphStyles from global;

  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  letter-spacing: -0.0888889px;
  color: #4A4A4A;
}

.error {
  composes: marketplaceModalPasswordMargins from global;
}

.submitButton {
  composes: button buttonFont buttonText buttonBorders buttonColorsPrimary from global;
}

.submitButtonYellow {
  composes: button buttonFont buttonText buttonBorders buttonColorsYellow from global;
  margin-top: 24px;
}

.modalIcon {
  composes: marketplaceModalIconStyles from global;
}