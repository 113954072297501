@import '../../../src/styles/propertySets.css';
@import '../../../src/styles/customMediaQueries.css';
/* @import '../../../src/styles/marketplaceDefaults.css'; */

.prejoiningContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 90%;
  width: 100%;
  margin: 0 auto;
  height: 100%;
  flex-direction: column;

  @media (--viewportLarge) {
    max-width: 80%;
    flex-direction: row;
  }

  & .meetingInitialContent {
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding-top: 5%;
    flex-direction: column;

    @media (--viewportLarge) {
      width: 50%;
      padding-right: 5%;
    }

    @media (--viewportviewportXLarge) {
      padding-right: 10%;
    }

    &>h2 {
      font-weight: var(--fontWeightBold);
      font-size: 25px;
      line-height: 30px;
      color: var(--marketplaceColor);
      margin: 0 0 20px 0;

      @media (--viewportviewportXLarge) {
        font-size: 30px;
        line-height: 40px;
        margin: 0 0 24px 0;
      }
    }

    &>p {
      font-weight: var(--fontWeightRegular);
      font-size: 16px;
      line-height: 25px;
      color: var(--marketplaceColor);
      margin: 0 0 24px 0;

      @media (--viewportviewportXLarge) {
        font-size: 20px;
        line-height: 30px;
        margin: 0 0 48px 0;
      }
    }

    & .joinMeetingSec {
      display: flex;
      align-items: center;

      & .ripple {
        display: inline-block;
        position: relative;
        width: 55px;
        height: 55px;
        margin-left: 38px;

        &>div {
          position: absolute;
          border: 4px solid #88474b;
          opacity: 1;
          border-radius: 50%;
          animation: ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;

          &:nth-child(2) {
            animation-delay: -0.5s;
          }
        }
      }

      &>button {
        padding: 3px;
        font-size: 18px;
        line-height: 27px;
        font-weight: var(--fontWeightBold);
      }
    }
  }

  & .meetingImage {
    width: 100%;
    padding: 2% 0;

    @media (--viewportLarge) {
      width: 50%;
      padding: 2%;
    }

    @media (--viewportviewportXLarge) {
      padding: 6%;
    }

    &>img {
      max-width: 100%;
    }
  }
}

.participantActions {
  position: absolute;
  display: flex;
  left: 12px;
  top: 12px;
}
.joinMeetingAudio{
  width: 100px;
  margin-left: 20px;
}
.roomWrapper{
  & video{
    object-fit: contain;
  }
}
.videoWrapper {
  height: calc(var(--vh) * 100);
  /* background-color: var(--marketplaceColor); */

  & .postjoiningContainer {
    /* width: 100%;
    height: 100%;
    max-width: 100%;
    padding: 0;
    display: flex; */
    background-color: var(--marketplaceColor);
    margin: 0 auto;
    padding: 25px 90px;
    height: 100vh;
    padding: 0;

    @media (--viewportLarge) {
      padding: 25px 90px;
    }

    @media (--viewportXLarge) {
      /* max-width: 90%; */
    }

    &.videoRoomContainer {
      display: flex;
      max-width: 100%;
      flex-direction: column;
      position: relative;
      height: 100vh;

      @media (--viewportXLarge) {
        flex-direction: row;
        height: 100%;
      }

      & .whiteBoardSec {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
        background-color: #fff;
        z-index: 2;
        padding: 20px;

        @media (--viewportXLarge) {
          width: 50%;
          position: relative;
          padding: 0;
        }
      }

      & .videoRoom {
        width: 100%;

        &>div {
          & aside {
            width: 205px !important;
          }
        }

        @media (--viewportXLarge) {
          width: 50%;
        }
      }
    }

    & .videoChat {

      /* background-color: red; */
      & :global(.video-root-27) {
        object-fit: cover;
      }
    }

    & .videoRoom {
      width: 100%;

      /* position: relative; */
      & :global(.div-root-8){
        width: 100%;
        & > div{
          width: 100%;
        }
      }
      &>div {
        &>div {
          &>div {
            &>div {
              height: 100vh;

              @media(--viewportLarge) {
                height: 93vh;
              }

              @media(--viewportXLarge) {
                height: 85vh;
              }
              @media (max-width:767px) {
                height: calc((var(--vh) * 100) - 60px);
                flex-direction: column;
                justify-content: center;
                min-height: 620px;
              }
            }
          }
        }
      }

      &>div {
        height: auto;
        bottom: 32px !important;
        align-items: flex-end;
        height: 56px;
        grid-gap: 20px;
        gap: 20px;

        @media(max-width: 460px) {
          gap: 15px;
        }

        & button {
          min-width: 56px !important;
          margin: 0;
          padding: 0;

          @media(max-width: 460px) {
            min-width: 35px !important;
            width: 35px;
            height: 35px;
          }

          & svg {
            width: 25px;

            @media(max-width: 460px) {
              width: 15px;
            }
          }
        }

        &>div {
          gap: 20px;
          width: 100%;
          margin: auto;
          display: flex;
          flex-direction: row;
          justify-content: center;

          /* height: 90vh; */
          & aside {
            right: 20px;
            border-radius: 10px;
            width: 170px;
            top: 20px;
            height: 170px;
            padding: 0;
            z-index: 1;
            position: absolute;
            background-color: rgb(255 255 255);

            &>div {
              &>div {
                height: 170px;

                & video {
                  object-fit: cover;
                  height: 100% !important;
                }
              }
            }
          }
        }
      }

      @media (max-width:480px) {
        & :global(.makeStyles-container-25>button) {
          width: 40px;
          height: 40px;
          font-size: 14px;
          margin: 4px;
        }

        & :global(.makeStyles-container-25>div>button) {
          width: 40px;
          height: 40px;
          font-size: 14px;
          margin: 4px;
        }
      }

      /* & :global(.makeStyles-container-18) {
        height: 100vh;

        @media(--viewportXLarge) {
          height: 85vh;
        }
      } */

      & :global(.makeStyles-identity-20) {
        border: solid 1px #ddd;
        background-color: #fff;
        padding: 4px 10px;
        font-size: 12px;
        border-radius: 4px;
        margin: 10px;
        display: inline-flex;
        align-items: center;
      }

      & :global(.makeStyles-identity-51) {
        border: solid 1px #ddd;
        background-color: #fff;
        padding: 4px 10px;
        font-size: 12px;
        border-radius: 4px;
        margin: 10px;
        display: inline-flex;
        align-items: center;
      }

      & :global(.makeStyles-infoContainer-21) {
        padding: 0;
      }

      & :global(.makeStyles-identity-14) {
        border: solid 1px #ddd;
        background-color: #fff;
        padding: 4px 10px;
        font-size: 12px;
        border-radius: 4px;
      }

      & :global(.aside-root-7) {
        right: 20px;
        border-radius: 10px;
        width: 170px;
        top: 20px;
        height: 170px;
        padding: 0;
        z-index: 1;
        position: absolute;
        background-color: rgb(255 255 255);

        &>div {
          &>div {
            height: 170px;

            & video {
              object-fit: cover;
              height: 100% !important;
            }
          }
        }

        @media (max-width:480px) {
          /* width: 100px; */
        }
      }

      & :global(.aside-root-37) {
        left: 10px;
        right: auto;
        width: 200px;
        bottom: 10px;
        padding: 0;
        z-index: 1;
        position: absolute;
        overflow-y: auto;
        background-color: rgb(255 255 255);
      }

      & :global(.aside-root-34) {
        right: 10px;
        width: 201px;
        bottom: 94px;
        padding: 0;
        z-index: 500;
        position: absolute;
        overflow-y: auto;
        background-color: rgb(255 255 255);
      }

      & :global(.makeStyles-indicator-16) {
        width: 6px;
        height: 6px;
        display: inline-block;
        background: #0c0;
        margin-right: 5px;
        border-radius: 100%;
      }

      & :global(.makeStyles-container-25) {
        transform: translate(50%, 0px);
        bottom: 20px;
        visibility: visible !important;
        opacity: 1;
      }

      & :global(.makeStyles-container-25:hover) {
        visibility: visible;
      }

      & :global(.makeStyles-container-22) {
        bottom: 32px !important;
        align-items: flex-end;
        height: 56px;
        gap: 20px;

        @media(max-width: 460px) {
          gap: 5px;
        }
      }

      & :global(.makeStyles-container-33:hover) {
        visibility: visible;
        opacity: 1;
      }

      & :global(.makeStyles-container-25 button) {
        @media(max-width: 460px) {
          width: 40px;
          height: 40px;
        }
      }

      & :global(.makeStyles-mainContainer-33) {
        border-radius: 0;
        z-index: 10000;
        max-width: 400px;
        padding: 12px;
        width: 100%;
        position: fixed;
        right: 0;
        top: 0;
        background: #fff;
        box-shadow: -4px 12px 18px #dddddd;

        @media(max-width: 460px) {
          max-width: 100%;
        }
      }
    }

    & .room {
      width: 100%;
      height: 100%;

      & .appContainer {
        width: 100%;
        height: 100%;
        display: flex;
        transition: 0.2s;
      }

      & .appMain {
        flex: 1;
        width: 100%;
        padding: 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      & .videoCallWrapper {
        width: 100%;
        height: 100%;
        overflow: hidden;
        position: relative;

        & .tutor {
          position: absolute;
          bottom: 80px;
          right: 10px;
          width: 160px;
          height: 100px;
          border-radius: 10px;
          overflow: hidden;

          @media (--viewportLarge) {
            width: 260px;
            height: 190px;
          }

          &>video {
            width: 100%;
            position: absolute;
            height: 100%;
            object-fit: cover;
            transform: scaleX(-1);
          }

          & .nameTag {
            background-color: rgba(212, 125, 79, 0.788);
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: var(--matterColorLight);
            padding: 4px 12px;
            display: inline-block;
            margin: 0;
            position: absolute;
            z-index: 1;
            left: 0;
            width: auto;
            top: auto;
            bottom: 0;
            height: auto;
            right: auto;
          }
        }

        & .videoParticipant {
          width: 100%;
          height: calc(100% - 70px);
          position: relative;
          background-color: var(--matterBgColorLight);
          border-radius: 10px;

          & video {
            width: 100%;
            height: 100%;
            object-fit: cover;
            /* transform: scaleX(-1); */
          }

          & .student {
            width: 100%;
            height: 100%;

            & .nameTag {
              background-color: rgba(212, 125, 79, 0.788);
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              color: var(--matterColorLight);
              display: inline-block;
              padding: 4px 12px;
              margin: 0;
              position: absolute;
              top: 0;
              z-index: 1;
              left: 0;
              width: auto;
              bottom: auto;
              height: auto;
              right: auto;
            }
          }
        }
      }
    }
  }
}

@keyframes ripple {
  0% {
    top: 26px;
    left: 26px;
    width: 0;
    height: 0;
    opacity: 0;
  }

  4.9% {
    top: 26px;
    left: 26px;
    width: 0;
    height: 0;
    opacity: 0;
  }

  5% {
    top: 26px;
    left: 26px;
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: 0px;
    left: 0px;
    width: 55px;
    height: 55px;
    opacity: 0;
  }
}

.leftSide {
  background-color: red;
}

.btnMute,
.btnCamera {
  width: 32px;
  height: 32px;
  border-radius: 4px;
  background-color: rgba(0, 15, 47, 0.5);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 16px;
  border: none;
}

.videoCallActions {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  padding: 10px;
  background-color: var(--matterColorLight);
}

.videoActionButton {
  background-repeat: no-repeat;
  background-size: 24px;
  border: none;
  box-shadow: var(--navigation-box-shadow);
  border-radius: 8px;
  cursor: pointer;
  outline: none;
  background-color: red;
  width: 40px;
  height: 40px;
  border-radius: 100px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  &>svg {
    width: 20px;

    @media (--viewportMedium) {
      width: 20px;
    }

    &>path {
      fill: var(--matterColorLight);
    }

    &.shareScreenIcon {
      width: 18px;

      @media (--viewportMedium) {
        width: 18px;
      }
    }

    &.chatIcon {
      width: 20px;

      @media (--viewportMedium) {
        width: 20px;
      }
    }

    &.endCallIcon {
      width: 20px;

      @media (--viewportMedium) {
        width: 23px;
      }

      &>path {
        stroke: var(--matterColorLight);
        fill: none;
      }
    }

    &.whiteBoardIcon {
      width: 18px;

      @media (--viewportMedium) {
        width: 20px;
      }

      &>g {
        &>path {
          fill: var(--matterColorLight);
        }
      }
    }
  }

  &.mic {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' stroke='%232c303a' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-mic-off' viewBox='0 0 24 24'%3E%3Cpath d='M1 1l22 22M9 9v3a3 3 0 005.12 2.12M15 9.34V4a3 3 0 00-5.94-.6'/%3E%3Cpath d='M17 16.95A7 7 0 015 12v-2m14 0v2a7 7 0 01-.11 1.23M12 19v4M8 23h8'/%3E%3C/svg%3E%0A");
    background-position: center;
  }

  &.camera {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' stroke='%232c303a' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-camera-off' viewBox='0 0 24 24'%3E%3Cpath d='M1 1l22 22M21 21H3a2 2 0 01-2-2V8a2 2 0 012-2h3m3-3h6l2 3h4a2 2 0 012 2v9.34m-7.72-2.06a4 4 0 11-5.56-5.56'/%3E%3C/svg%3E%0A");
    background-position: center;
  }
}

.rightSide {
  margin-left: auto;
  width: 100%;
  height: 100%;
  transition: all 300ms cubic-bezier(0.19, 1, 0.56, 1);
  background-color: var(--matterColorLight);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  padding-top: 30px;

  @media (--viewportMedium) {
    max-width: 400px;
    padding: 0 16px;
    width: 100%;
    position: relative;
  }

  & .cross {
    font-size: 30px;
    font-weight: 300;
    position: absolute;
    top: 10px;
    right: 15px;
    cursor: pointer;
    display: block;

    @media (--viewportMedium) {
      display: none;
    }

    &:hover {
      color: var(--marketplaceColorLight);
    }
  }

  &.show {
    display: block;
    transform: translateX(0);
  }

  & .messageInputArea {
    display: flex;
    align-items: center;
    padding: 10px;

    &>svg {
      width: 20px;
      margin-right: 10px;

      &.smile {
        width: 18px;
      }

      &:hover {
        cursor: pointer;
        fill: var(--marketplaceColorLight);

        &>path {
          fill: var(--marketplaceColorLight);
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }

    & .msgArea {
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: solid 1px var(--matterBorderColor);
      padding: 0 10px;
      border-radius: 4px;
      margin-right: 10px;

      &>input {
        flex-grow: 1;
        padding: 0;
        border: none;
        padding-right: 10px;
        height: 40px;
      }

      & .sendbtn {
        border: none;
        padding: 0px;
        background-color: none;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &>svg {
          width: 64px;
          margin: 3px;
        }

        &:hover {
          &>svg {
            stroke: var(--marketplaceColorLight);
          }
        }
      }
    }
  }
}

.chatContainer {
  background-color: var(--chat-background);
  display: flex;
  flex-direction: column;
  height: 100%;

  @media (--viewportMedium) {
    border: 1px solid #dedede;
    border-radius: 10px;
    height: calc(100% - 72px);
  }
}

.chatArea {
  flex: 1;
  overflow-y: auto;
  padding: 16px;
}

.messageWrapper {
  display: flex;
  margin-bottom: 14px;

  & .profilePicture {
    width: 32px;
    height: 32px;
    border-radius: 100px;
    background-color: var(--matterColorAnti);
    margin-right: 10px;
  }

  & .ownMessageContent {
    & img {
      width: 35px;
      height: 35px;
      border-radius: 50%;
    }

    &::before {
      position: absolute;
      left: -18px;
      top: 0px;
      content: '';
      border-top: solid 12px transparent;
      border-bottom: solid 10px transparent;
      border-left: solid 10px transparent;
      border-right: solid 12px var(--matterColorNegative);
    }
  }

  & .messageContent {
    width: calc(100% - 42px);
    padding: 5px 10px;
    border-radius: 7px;
    position: relative;

    &::before {
      display: none;
      position: absolute;
      left: -18px;
      top: 0px;
      content: '';
      border-top: solid 12px transparent;
      border-bottom: solid 10px transparent;
      border-left: solid 10px transparent;
      border-right: solid 12px rgb(252 246 243);
    }

    &>p {
      margin: 0 0 5px 0;
      font-size: 14px;
      color: var(--marketplaceColorLight);
      line-height: 20px;
    }

    & .msg {
      font-size: 12px;
      line-height: 20px;
    }

    & .date {
      font-size: 10px;
      line-height: 14px;
      width: 90px;
      text-align: right;
    }
  }
}

.messageBoxWrapper {
  display: flex;
  gap: 10px;

  &>a {
    background-image: none;
  }

  & img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
  }

  & .messageBodyBox {
    width: 100%;

    & .rightBody {
      display: flex;
      /* align-items: center; */
      justify-content: space-between;
      margin-bottom: 5px;

      & h4 {
        font-size: 14px;
        line-height: 23px;
        margin-bottom: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }
  }
}

.shareMeetingLinkSec {
  width: 100%;
  max-width: 360px;
  border-radius: 10px;
  background-color: var(--matterColorLight);
  padding: 14px;
  position: absolute;
  bottom: 6px;
  left: 6px;
  box-shadow: 0px 0px 11px #33333338;
  z-index: 1;

  @media (--viewportSmall) {
    bottom: 10px;
    left: 10px;
  }

  @media (--viewportMedium) {
    bottom: 30px;
    left: 30px;
  }

  &>h2 {
    font-size: 16px;
    font-weight: 600;
    margin: 0 0 10px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &>span {
      font-size: 30px;
      font-weight: 300;
      line-height: 20px;
      cursor: pointer;

      &:hover {
        color: var(--matterColorDark);
      }
    }
  }

  &>p {
    font-size: 14px;
    line-height: 130%;
    font-weight: 400;
  }

  & .copyLinkSec {
    background-color: var(--matterBgColorLight);
    border-radius: 4px;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &>span {
      /* @apply --marketplaceH5FontStyles; */
      margin: 0;
      display: -webkit-inline-box;
      overflow: hidden;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      width: 80%;
    }

    &>svg {
      cursor: pointer;

      &:hover {
        fill: var(--matterColorDark);
      }
    }
  }
}

.whiteBoardWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (--viewportXLarge) {
    padding-bottom: 20px;
    padding-right: 20px;
  }

  & .canvasArea {
    width: calc(100% - 80px);
    border: solid 1px #ddd;
    height: 100%;

    &>canvas {
      width: 100% !important;
      height: 100% !important;
    }
  }

  & .drawingPanel {
    background-color: #fff;
    width: 60px;
    border-radius: 100px;
    border: solid 1px #ddd;
    padding: 10px 6px;
    display: flex;
    flex-direction: column;
    align-items: center;

    & .whiteBoardIcons {
      border: none;
      margin-bottom: 20px;
      cursor: pointer;

      &:last-child {
        margin-bottom: 0;
      }

      &>svg {
        width: 24px;

        &>path {
          stroke: var(--marketplaceColor);
        }
      }

      &>input {
        height: 30px;
        width: 30px;
        border: none;
        padding: 0;
      }

      &>select {
        border-radius: 3px;
        padding: 0;
        height: 30px;
        background-position: center right 4px;
        text-align: center;
        min-width: 36px;
        padding-right: 18px;
        padding-left: 4px;
      }
    }
  }
}

.closeWhiteBoard {
  position: absolute;
  right: 10px;
  top: 10px;
  text-align: center;

  @media (--viewportXLarge) {
    display: none;
  }

  &>span {
    background-color: var(--marketplaceColor);
    color: var(--matterColorLight);
    border-radius: 100px;
    display: inline-flex;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    font-weight: 300;
  }
}

.screenShare {
  transform: scaleX(0);
}

.notJoinedAnyone {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  font-size: 16px;
  transform: translate(-50%, -50%);
  text-align: center;
}

.ownMessage {
  justify-content: flex-end;

  &>div {
    background-color: rgb(217 156 124 / 10%);
  }
}

.otherMessage {
  &>div {
    background-color: #f6f4f4;
  }
}

video {
  object-fit: cover;

  @media(max-width: 768px) {
    width: 100% !important;
    height: auto !important;
  }
}
.countDownWrapper{
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgb(0, 0, 0,0.4);
  z-index: 100;
  & .round{
    height: 150px;
    width: 150px;
    border-radius: 50%;
  	background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 80px;
  color: #fff;
  }
  & .clickPauseText{
    color: #ffffff;
    text-align: center;
  }
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.modalReminder{
  @media (max-width:767px) {
    & > div{
      padding: 20px !important;
      min-height: auto !important;
      background-color: transparent !important;
      justify-content: center !important;
      align-items: center !important;
      & > div{
        min-height: auto !important;
        /* background-color: transparent !important; */
        flex-grow: 0 !important;
        height: auto !important;
        & > button{
          position: absolute !important;
          top: 0;
          padding: 10px !important;
        }
        & p{
          margin-top: 20px;
        }
      }
    }
  }
}