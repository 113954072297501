.flex{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;

    @media (max-width:767px) {
    flex-direction: column;
    gap: 8px;
 
    }

}
.flexRow{
    display: flex;
    align-items: center;
    flex-direction: row;
}

.transcriptItem{
    box-shadow: 0px 6.25px 20px 0px rgba(0, 0, 0, 0.13);
    margin-bottom: 24px;
    border-radius: 5px;
    padding: 10px 20px 20px;
    /* min-width: 700px; */
    @media (max-width:767px) {
        box-shadow: 2px 2px 7px 0px rgba(0, 0, 0, 0.13);
    }
  
    & .requestBtn{
        background: #F6861F;
        border-radius: 4px;
        filter: drop-shadow(6px 6px 0px #E9B24A);
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'Source Serif Pro';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 1px;
        text-transform: capitalize;
        color: #FFFFFF;
        cursor: pointer;
        padding: 4px;
    }
}

.transcriptItemContainer{
    @media (max-width:767px) {
        padding: 0px 3px; 
    }
}

.skeleton {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

.transcriptRequest{
    /* flex: 1; */
    margin: 0;
    @media (max-width: 767px) {
    margin-left: 2px !important;
    padding-left: 3px !important;
    }
}