.calendarButton{
  padding: 22px;
}

.btnCalendar{
  background: #fff;
  border-radius: 50px;
  border: 1px solid #ddd;
  color: #000;
  box-shadow: none;
  filter: none;
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
  & img{
    height: 30px;
    width: 30px;
  }
}