@import '../../../../styles/customMediaQueries.css';

.root {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;

  &:nth-of-type(odd) {
    /* background-color: #fafafa; */
  }
}

.sectionContent {
  padding: 32px 0;
  position: relative;

  @media (--viewportMedium) {
    padding: 64px 0;
  }
}

.backgroundImageWrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.findProBox {
  padding: 32px 0 0;
  position: relative;
  max-width: 100%;
  margin: 0 auto;
  position: relative;
  margin-bottom: 35px;

  @media (--viewportMedium) {
    padding: 67px 0 0 0;
    max-width: 1330px;
  }
}

.remainFindProBox {
  padding: 0 0 32px 0;
  position: relative;
  max-width: 100%;
  margin: 0 auto;
  position: relative;
  margin-bottom: 35px;

  @media (--viewportMedium) {
    padding: 0 0 87px 0;
    max-width: 1330px;
  }
}

.blogBox {
  padding: 32px 0;
  position: relative;

  @media (--viewportMedium) {
    padding: 90px 0 100px 0;
  }
}

:global(#hero) > div img {
  /* object-position: right !important ; */
  object-position: 92% 50% !important;
}

:global(#mission-together) {
  & header {
    & h2 {
      color: #fff;
      text-align: left;
    }
  }
}

.howItWorks {
  padding: 32px 0;
  position: relative;

  @media (--viewportMedium) {
    padding: 64px 0;
  }
  & header {
    & h2 {
      text-align: center;
      max-width: 100%;
      text-align: center;
      justify-self: center;
      font-family: 'Source Serif Pro';
      font-style: normal;
      font-weight: 400;
      font-size: 46px;
      line-height: 56px;
      letter-spacing: -1.25px;
      color: #000;
    }
  }
  & > div {
    padding-top: 20px;
  }
}

.subscribeSection {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #6dabc8;
  text-align: center;
  padding: 40px 20px;
  & h2 {
    text-align: center;
    color: #fff;
    font-family: 'Source Serif Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 46px;
    line-height: 56px;
    letter-spacing: -1.25px;
    margin-bottom: 40px;
  }
  & .inputWrapper {
    display: flex;
    gap: 16px;
    max-width: 650px;
    margin: 0 auto;
    @media (max-width:576px) {
      flex-direction: column;
    }
    & input {
      width: 100%;
      background-color: #fff;
      padding: 4px 0 2px 20px;
    }
    & .subscribeBtn {
      max-width: 200px;
      @media (max-width:576px) {
        max-width: 100%;
      }
    }
  }
}