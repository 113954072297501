.root {}

.marketplaceStroke {
  stroke: var(--marketplaceColor);
}

.successFill {
  fill: #DA8240;
}

.checkStroke {
  stroke: var(--matterColorLight);
}