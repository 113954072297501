@import '../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;
}

.email {
  margin-top: 33px;

  @media (--viewportMedium) {
    /* margin-top: 38px; */
  }
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
  margin-top: 65px;
}

/* Adjust the bottomWrapper's margin to match modals without the helper text */
.bottomWrapperText {
  composes: marketplaceModalBottomWrapperText from global;

  & span {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    /* or 185% */

    text-align: center;

    color: #B2B2B2;
  }
}

/* Helper links */
.modalHelperLink {
  composes: marketplaceModalHelperLink from global;
}

/* Helper texts for the links, not the actual links */
.modalHelperText {
  composes: marketplaceModalHelperText from global;
}

.modalHelperTextBox {
  & a {
    & span {
      color: #4A4A4A;
    }
  }
}