@import '../../styles/customMediaQueries.css';

.radioButtons {
    margin-top: 42px;
  
    /* & .rowCheckBox {
      & label {
        & span {
          font-family: 'Source Serif Pro';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: 0.01em;
          color: #000000;
        }
      }
    } */
  }

  .inputWrapper {
    & .inputSelect {
      margin-bottom: 38px;
  
      & label {
        font-family: 'Source Sans Pro';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #6c6d7a;
      }
  
      & select {
        font-family: 'Source Serif Pro';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 32px;
        color: #8f908d;
        
      }
    }
  }
