/*
If you need to add css to the Logo you can do it here.

.logoMobile {
}
*/
.mobileLogo{
    width: 100%;
    object-fit: contain;
    max-height: 36px;
}