@import '../../styles/customMediaQueries.css';

.root {
  /* Expand to available space */
  flex-grow: 1;

  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    padding-top: 1px;
  }
}

.features {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.error {
  color: var(--failColor);
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;
  width: 241px;

  @media (--viewportLarge) {
    display: inline-block;
    margin-top: 100px;
  }

  @media(max-width: 768px) {
    margin-bottom: 50px;
  }
  @media(max-width: 460px) {
    width: 100%;
  }
}

.subTitle {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #40526E;
  margin-bottom: 36px;

  @media(max-width: 768px) {
    font-size: 16px;
    line-height: 20px; 
   }
}

.dividerLine {
  background-color: #E0E0E0;
  height: 1px;
  margin: 38px 0;
}

.accordanceBox {
  margin-bottom: 38px;
}

.accordion {
  transition: all 0.3s ease-in-out;
  padding: 24px 22px;
  background: #FFFFFF;
  box-shadow: 0px 3px 4px rgb(0 0 0 / 14%), 0px 3px 3px rgb(0 0 0 / 12%), 0px 1px 8px rgb(0 0 0 / 20%);
  border-radius: 4px;
  margin-bottom: 61px;
}

.accordion[open] .accordionTitle {
  background-image: url("../../assets/uparrow.png");
}

.accordion .accordanceBoxWrap {
  padding-top: 23px;
  padding-bottom: 47px;
}

.accordion .accordionTitle {
  list-style-type: none;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 700;
  color: #555555;
  background-image: url("../../assets/downarrow.png");
  background-repeat: no-repeat;
  background-position: right 0.35rem top 0.625rem;
  background-size: 16px 8px;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0.05em;
  color: #212121;

  @media(--) {
    background-position: right 0.75rem top 0.625rem;
  }
}

.accordion .accordionTitle::marker::-webkit-details-marker {
  display: none;
}

.rowForm {
  display: flex;
  justify-content: space-between;
  margin-bottom: 37px;

  @media(max-width: 520px) {
    flex-wrap: wrap;
    gap: 20px;
  }

  & .rowInput {
    width: calc(50% - 25px);

    @media(max-width: 520px) {
      width: 100%;
    }
  }

  & .selectDropdown {
    width: calc(50% - 25px);

    @media(max-width: 520px) {
      width: 100%;
    }

    &>div {
      &>div {
        & span {
          display: none;
        }

        &>div {
          min-height: 40px;
          border: none;
          border-bottom: 2px solid var(--successColor);
          border-radius: 0;
          box-shadow: none;

          &>div {
            padding: 0;
          }
        }
      }
    }
  }
}

.fullInput {
  &>div {
    &>div {
      & span {
        display: none;
      }

      &>div {
        min-height: 40px !important;
        border: none;
        border-bottom: 2px solid var(--successColor);
        border-radius: 0;
        box-shadow: none;

        &>div {
          padding: 0;
        }
      }
    }
  }
}

.listBoxCertificate {
  margin-top: 37px;
}

.certificationInput {
  position: relative;
  margin-top: 15px;

  &>div {
    padding: 0 25px;
  }

  & .plusIcon {
    top: 8px;
    right: 0;
    position: absolute;
    cursor: pointer;
  }
  & .deleteIcon{
    top: 0px;
    right: 20px;
    position: absolute;
    cursor: pointer;
  }

  & .inputDot {
    position: absolute;
    left: 0;
    top: 8px;
  }
}

.featureForm {
  & label {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #6C6D7A;
  }
}

.requestLabel {
  margin-top: 35px;
}

.selectBox {
  color: var(--matterColorAnti);
  border-bottom-color: var(--attentionColor);
  border-bottom-width: 2px;
  border-bottom-style: solid;
  cursor: pointer;
  background-image: url(data:image/svg+xml;utf8,<svg width=\"14\" height=\"9\" viewBox=\"0 0 14 9\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"M6.53 7.472c.26.26.68.26.94 0l5.335-5.333c.26-.263.26-.684 0-.944-.26-.26-.683-.26-.943 0L7 6.056l-4.862-4.86c-.26-.26-.683-.26-.943 0-.26.26-.26.68 0 .943L6.53 7.47z\" stroke=\"%234A4A4A\" fill=\"%234A4A4A\" fill-rule=\"evenodd\"/></svg>);
  background-size: 16px 16px;
  background-position: center right;
  transition: border-bottom-color var(--transitionStyle);
}

.startYearInput {
  & ul {
    position: absolute;
    background: #fff;
    height: 230px;
    overflow: auto;
    border: 1px solid #ededed;
    top: 21px;
    z-index: 9999;
    padding: 10px 0;
    width: 100%;

    & li {
      padding: 0 13px;

      &:hover {
        background-color: #ddd;
      }
    }
  }
}

.selectInput {
  position: relative;
}

.unselectList {
  color: var(--matterColorAnti);
}

.selectItem {
  color: #4a4a4a;
}

.addAnotherTradeBtn{
  width: max-content;
  min-height: 36px;
  font-size: 14px;
  padding: 2px 20px;
  background: #f6861f;
  border-radius: 4px;
  filter: drop-shadow(6px 6px 0px #e9b24a);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
}