.articleMain {
  /* 720px + (2 * 32px) == 784 */
  max-width: 784px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 32px;
  margin: 0 auto;
  padding: 0 32px;
  @media (max-width:767px) {
    padding: 0 18px;
  }
}

.noSidePaddings {
  padding-left: 0;
  padding-right: 0;
}

.termServices {
  & h2 {
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    font-family: 'Source Sans Pro';
    margin-top: 20px;
  }

  & p {
    max-width: 100%;
    font-family: 'Source Sans Pro';
  }

  & ol {
    padding-left: 17px;
    font-family: 'Source Sans Pro';
  }
}

.termHeading {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  & h1 {
    font-size: 24px;
    font-family: 'Source Sans Pro';
    text-decoration: underline;
    font-weight: 500;
  }

  & p {
    font-size: 20px;
    font-family: 'Source Sans Pro';
    font-weight: 500;
    margin-top: 5px;
  }
}