.strokeMarketplaceColor {
  stroke: var(--marketplaceColor);
}

.fillSuccessColor {
  fill: #DA8240;
}

.strokeLightColor {
  stroke: var(--matterColorLight);
}
