@import '../../../../styles/customMediaQueries.css';

.baseColumn {
  max-width: var(--contentMaxWidth);
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 32px;
  margin: 0 auto;
  padding: 32px;

  @media (max-width: 768px) {
    max-width: 100%;
  }
}

.featuresMain {
  composes: baseColumn;
}

.block {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-auto-flow: dense;
  gap: 0;

  @media (--viewportMedium) {
    gap: 64px;
    flex-direction: row-reverse;
  }

  &:nth-child(even) {
    @media (--viewportMedium) {
      flex-direction: row;
    }
  }
}

.noSidePaddings {
  padding-left: 0;
  padding-right: 0;
}

.heroContent {
  & > div {
    &:nth-child(2) {
      height: 608px;
      display: flex;
      align-items: center;
      @media (max-width:767px) {
        height: auto;
        min-height: 644px;
      }
    }
  }
}

.heroData {
  & h1 {
    font-family: 'Source Serif Pro';
    font-style: normal;
    font-weight: 900;
    font-size: 64px;
    line-height: 72px;
    letter-spacing: -1.42222px;
    color: #ffffff;
    padding-right: 10%;

    @media (max-width: 768px) {
      font-size: 35px;
      line-height: 45px;
      padding-right: 0;
      letter-spacing: 0.01rem;
    }
    @media (max-width: 600px) {
      line-height: 40px;
    }
  }

  & p {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 36px;
    letter-spacing: -0.1875px;
    color: #ffffff;
    margin-top: 34px;
    padding-right: 45%;

    @media (max-width: 768px) {
      padding: 0;
      font-size: 30px;
      line-height: 36px; /* 120% */
    }
  }

  & a {
    margin-top: 41px;
    background: #f6861f;
    border-radius: 4px;
    filter: drop-shadow(6px 6px 0px #e9b24a);
    width: 247px;
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Source Serif Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;
    letter-spacing: 1px;
    text-transform: capitalize;
    color: #ffffff;
    & * {
      position: relative;
      z-index: 2;
    }
    &:hover {
      background-color: #d8894d;
    }
    @media (max-width: 768px) {
      width: 100%;
      height: 51px;
      position: relative;
      /* filter: unset; */
      /* &:after {
        position: absolute;
        content: '';
        left: 6px;
        right: -6px;
        top: 10px;
        border-radius: 6px;
        background-color: #e9b24a;
        bottom: -7px;
        z-index: 0;
      } */
    }
  }
}