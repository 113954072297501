@import '../../../../styles/customMediaQueries.css';

.baseColumn {
  max-width: var(--contentMaxWidth);
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 32px;
  margin: 0 auto;
  padding: 32px;

  @media (max-width: 768px) {
    max-width: 100%;
  }
}

.blogWrapper {
  /* max-width: var(--contentMaxWidth); */
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 50px;
  margin: 0 auto;
  padding: 0;

  @media (max-width: 1500px) {
    padding: 0 100px;
  }

  @media (max-width: 768px) {
    padding: 0 25px;
  }

  @media (max-width: 560px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.oneColumn {
  composes: baseColumn;
}

.twoColumns {
  composes: baseColumn;

  @media (--viewportMedium) {
    grid-template-columns: repeat(2, calc((100% - 32px) / 2));
  }
}

.threeColumns {
  composes: baseColumn;

  @media (--viewportMedium) {
    grid-template-columns: repeat(3, calc((100% - 2 * 32px) / 3));
  }
}

.fourColumns {
  composes: baseColumn;

  @media (--viewportMedium) {
    grid-template-columns: repeat(4, calc((100% - 3 * 32px) / 4));
  }
}

.noSidePaddings {
  padding-left: 0;
  padding-right: 0;
}

.missionBox {
  font-family: 'Source Serif Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 50px;
  letter-spacing: -1.25px;
  padding-right: 38%;
  margin-bottom: 28px;

  @media (max-width: 768px) {
    padding-right: 0;
    font-size: 32px;
  }
  @media (max-width: 600px) {
    font-size: 26px;
    line-height: 40px;
  }
}

.missionImage {
  position: relative;
  @media (max-width:767px) {
   /* overflow: hidden; */
  }
  &:before {
    content: '';
    position: absolute;
    height: 8px;
    width: 192px;
    background-color: #3d5a81;
    top: 0;
    left: 17%;
    z-index: 9;
  }

  &>div {
    &:nth-child(2) {
      border-radius: 4px;
      padding: 90px 0 104px 0;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #6dabc8;
    }
  }

  & img {
    object-fit: cover;
    position: absolute;
    width: 421px;
    height: 421px;
    z-index: 9;
    right: 0;
    top: 0;
    @media (max-width:767px) {
      width: 300px;
      height: 300px;
      /* right: -91px; */
    }
  }
}

.togetherList {
  /* background-color: red; */

  & h3 {
    font-family: 'Source Serif Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    color: #ffffff;
    margin-bottom: 14px;
  }

  & p {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #ffffff;
    margin-top: 0;
    @media(max-width: 768px){
      font-size: 15px
    }
  }
}

.findPro {
  & h3 {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 21px;
    line-height: 32px;
    letter-spacing: -0.2625px;
    color: #003047;
  }
}

.sliderHeading {}

.blogWrapperHead {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 0;

  @media (max-width: 600px) {
    flex-wrap: wrap;
  }

  & h2 {
    font-family: 'Source Serif Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 46px;
    line-height: 56px;
    letter-spacing: -1.25px;
    color: #3c5b81;
    max-width: 80%;
    text-align: left;

    @media (max-width: 768px) {
      font-size: 22px;
      line-height: 30px;
    }
  }

  & .headingLink {
    & a {
      font-family: 'Source Sans Pro';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      text-decoration-line: underline;
      color: #40526e;

      @media (max-width: 768px) {
        font-size: 14px;
        line-height: 26px;
      }
    }
  }
}

.sliderMain {
  max-width: var(--contentMaxWidth);
  margin: 0 auto;
  padding: 0 32px;
  position: relative;
}

.blogMain {
  margin: 0 auto;
  position: relative;
  margin-bottom: 35px;
  max-width: 1330px;

  @media (max-width: 1500px) {
    padding: 0 100px;
  }

  @media (max-width: 768px) {
    max-width: 100%;
    padding: 0 25px;
  }
}

.blogWrapper {
  margin: 0 auto;
  position: relative;
  margin-bottom: 35px;
  max-width: 1330px;

  @media (max-width: 768px) {
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
}

.blogCard {
  &>div {
    &:nth-child(2) {
      margin-top: 27px;

      & h3 {
        font-family: 'Source Serif Pro';
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        line-height: 28px;
        letter-spacing: -0.266667px;
        color: #000000;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      & p {
        font-family: 'Source Sans Pro';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 23px;
        letter-spacing: -0.266667px;
        color: #000000;
        margin-top: 12px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      & a {
        background: #e5f2f3;
        border-radius: 4px;
        filter: drop-shadow(6px 6px 0px #3c5b81);
        font-family: 'Source Serif Pro';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 23px;
        padding: 19px;
        text-align: center;
        letter-spacing: 1px;
        text-transform: capitalize;
        color: #40526e;
        width: 172px;
        margin-top: 28px;

        @media (max-width: 768px) {
          font-size: 15px;
          line-height: 12px;
          width: 125px;
          height: 46px;
        }

        &:hover {
          background: #d3ebed;
        }
      }
    }
  }
}