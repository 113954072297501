@import '../../../../styles/customMediaQueries.css';

.carouselContainer {
  display: flex;
  align-items: center;
  scroll-behavior: smooth;
  padding: 64px 0 0 0;

  @media (max-width: 1500px) {
    padding: 64px 100px 0 100px;
  }

  &:hover .carouselArrows {
    opacity: 1;
  }

  /* smartphones, touchscreens: we don't need to show arrows */
  @media (any-hover: none) and (pointer: coarse) {
    &:hover .carouselArrows {
      opacity: 0;
    }
  }
}

.sliderSlide {
  display: flex;
  align-items: center;
  scroll-behavior: smooth;
  padding: 64px 0 0 0;

  @media (max-width: 1500px) {
    padding: 64px 100px 0 100px;
  }

  @media (max-width: 768px) {
    padding: 30px 0 14px 22px;
    max-width: 100vw;
  }

  &:hover .carouselArrows {
    opacity: 1;
  }

  /* smartphones, touchscreens: we don't need to show arrows */
  @media (any-hover: none) and (pointer: coarse) {
    &:hover .carouselArrows {
      /* opacity: 0; */
    }
  }

  &>div {
    opacity: 1;
    
  }
}

.carouselArrows {
  opacity: 0;
  z-index: 2;
  transition: all ease-in-out 500ms;
}

.carouselArrowPrev,
.carouselArrowNext {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 2;

  width: 48px;
  height: 48px;
  max-width: 48px;
  min-width: 48px;
  min-height: 48px;
  max-height: 48px;
  margin-top: -64px;
  border-radius: 100%;
  border: none;

  background-color: lightgrey;
  opacity: 0.9;
  /* font-size: 30px; */
  color: black;
  transition: all ease-in-out 100ms;

  &:hover {
    opacity: 1;
    cursor: pointer;
    transition: all ease-in-out 100ms;
    background: #c4c4c4;
    color: white;
  }

  & img {
    width: 25px;
    height: 25px;
  }
  @media (max-width: 768px) {
    display: none;
  }
}

.carouselArrowPrev {
  left: 48px;
}

.carouselArrowNext {
  right: 48px;

  & img {
    rotate: 180deg;
  }
}

.notEnoughBlocks {
  @media (--viewportMedium) {
    display: none;
  }
}

.baseCarousel {
  display: flex;
  flex-wrap: nowrap;
  width: var(--carouselWidth);
  padding-bottom: 24px;

  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  padding-bottom: 48px;

  /* @media (max-width: 768px) {
    width: 767px;
  } */

  @media (max-width: 560px) {
    /* width: 500px; */
    padding-bottom: 15px;
  }

  &::-webkit-scrollbar {
    height: 7px;
  }

  &::-webkit-scrollbar-track {
    background: #e5f2f3;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: #40526e;
  }
}

.oneColumn,
.twoColumns,
.threeColumns,
.fourColumns {
  composes: baseCarousel;
}

.block {
  flex: 0 0 auto;
  /* 64px = horizontal layout paddings */
  width: calc(min(var(--contentMaxWidthPages), var(--carouselWidth)) - 64px);

  margin-right: 16px;
  scroll-snap-align: center;

  transform: translateX(0px);

  &:last-of-type {
    padding-right: 32px;
    /* 32px (padding-right above) */
    width: calc(min(var(--contentMaxWidthPages), var(--carouselWidth)) - 32px);
  }

  @media (--viewportMedium) {
    margin-right: 32px;
    transform: translateX(32px);
  }

  @media (min-width: 1200px) {
    /* Offset the start of the carousel so it follows the global grid layout (e.g. (1400 - 1200) / 2 = 100px) */
    /* Removing this uses the full page width for the slider pane */
    transform: translateX(max(calc((var(--carouselWidth) - var(--contentMaxWidthPages)) / 2 + 32px), 0px));

    &:last-of-type {
      padding-right: 32px;
      width: calc(min(var(--contentMaxWidthPages), var(--carouselWidth)) - 32px);
      /* 32px (padding-right above) */
    }
  }
}

.oneColumn .block {
  @media (--viewportMedium) {
    /* 64px (horizontal layout paddings) */
    width: calc(min(var(--contentMaxWidthPages), var(--carouselWidth)) - 64px);

    &:last-of-type {
      /* 32px (padding-right) */
      width: calc(min(var(--contentMaxWidthPages), var(--carouselWidth)) - 32px);
    }
  }
}

.twoColumns .block {
  @media (--viewportMedium) {
    /* 64px (horizontal layout paddings) - 32px (one gutter á 32px) / 2 (number of columns) */
    width: calc((min(var(--contentMaxWidthPages), var(--carouselWidth)) - 64px - 32px) / 2);

    &:last-of-type {
      /* 32px (padding-right above) / 2 (number of columns) */
      width: calc((min(var(--contentMaxWidthPages), var(--carouselWidth)) - 32px) / 2);
    }
  }
}

.threeColumns .block {
  @media (--viewportMedium) {
    /* 64px (horizontal layout paddings) - 32px (two gutters á 32px) / 3 (number of columns) */
    width: calc((min(var(--contentMaxWidthPages), var(--carouselWidth)) - 64px - 64px) / 3);

    &:last-of-type {
      /* 32px (padding-right above) / 3 (number of columns) */
      width: calc((min(var(--contentMaxWidthPages), var(--carouselWidth)) - 32px) / 3);
    }
  }
}

.fourColumns .block {
  @media (--viewportMedium) {
    /* 64px (horizontal layout paddings) - 96px (three gutters á 32px) / 4 (number of columns) */
    width: calc((min(var(--contentMaxWidthPages), var(--carouselWidth)) - 64px - 96px) / 4);

    &:last-of-type {
      /* 32px (padding-right above) / 4 (number of columns) */
      width: calc((min(var(--contentMaxWidthPages), var(--carouselWidth)) - 32px) / 4);
    }
  }
}


.sectionTitle {
  max-width: 30ch;
  text-align: center;
  justify-self: center;
  font-family: 'Source Serif Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 46px;
  line-height: 56px;
  letter-spacing: -1.25px;
  color: #4a4a4a;

  @media (max-width: 768px) {
    font-size: 22px;
    line-height: 30px;
  }
}

.sliderBoxWrapper {
  & h3 {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 21px;
    line-height: 32px;
    color: #4a4a4a;

    @media (max-width: 768px) {
      font-size: 16px;
      line-height: 26px;
    }
  }

  & a {
    position: absolute;
    top: 0;
    width: 100%;
    height: 85%;
    opacity: 0;
  }
}

.sliderWrapperHead {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  /* padding: 0 40px; */

  @media (max-width: 1500px) {
    padding: 0 100px;
  }

  @media (max-width: 768px) {
    padding: 0 25px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }

  & h3 {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 300;
    font-size: 46px;
    line-height: 56px;
    letter-spacing: -1.25px;
    color: #003047;

  }

  & .headingLink {
    @media (max-width: 768px) {
  margin-top: 0;
    }
    & a {
      font-family: 'Source Sans Pro';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      text-decoration-line: underline;
      color: #40526e;

      @media (max-width: 768px) {
        font-size: 18px;
        line-height: 30px;
      }
    }
  }
}
:global(#remain-find-pro-2-container){
  padding-top: 0 !important;

}

:global(#remain-find-pro){
  & > div{
    margin-bottom: 0;
  }
}
:global(#find-your-pro){
  & > div{
    @media (max-width:767px) {
      margin-bottom: 0;
    }
  }
}