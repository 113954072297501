@import '../../styles/customMediaQueries.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;

  padding-top: 1px;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.formMargins {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.title {
  composes: formMargins;
}

.description {
  composes: formMargins;
  flex-shrink: 0;
}

.certificate {
  composes: formMargins;
}

.submitButton {
  margin-top: auto;
  margin-bottom: 0;
  flex-shrink: 0;
  width: 241px;

  @media (--viewportLarge) {
    display: inline-block;
  }

  @media(max-width: 768px) {
    margin-bottom: 50px;
  }
  @media(max-width: 460px) {
    width: 100%;
  }
}

.rowForm {
  display: flex;
  justify-content: space-between;
  margin-bottom: 37px;

  @media(max-width: 460px) {
    flex-wrap: wrap;
    gap: 20px;
  }
  @media (max-width: 768px) {
    gap: 20px;
  }

  & .rowInput {
    width: calc(50% - 25px);

    @media(max-width: 460px) {
      width: 100%;
      gap: 20px;
    }
  }
}

.fullInput {
  margin-bottom: 37px;
}

.rowCheckBox {
  & label {
    &>span {
      &:nth-child(1) {
        margin-right: 30px;
      }
    }

    & span {
      font-family: 'Source Serif Pro';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.01em;
    }
  }
}