.fake-state {
  padding: 0.5em;
  background-color: #e8e8e8;
  text-align: center;
}

.fake-state input {
  /* margin-right: 3em; */
  width: auto;
}
.fake-state label {
margin: 0;
}


.fake-state{
  display: flex;
  align-items: center;
  gap: 12px;
}

.fake-sate-input{
  display: flex;
  align-items: center;
  gap: 8px;
}