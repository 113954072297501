@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
  background-color: var(--matterColorBright);
  position: relative;
}

.longInfo {
  flex-wrap: wrap;
}

.filters {
  display: flex;

  &>*:not(:last-child) {
    margin-right: 8px;
    margin-bottom: 8px;
    height: auto;
  }
}

.searchOptions {
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
}

.searchResultSummary {
  composes: h4 from global;
  line-height: 20px;
  margin-top: 10px;
  margin-bottom: 11px;
  margin-left: 0px;
  margin-right: 8px;
}

.noSearchResults {
  composes: h4 from global;

  /* Reserves 48px of vertical space */
  line-height: 20px;
  margin-top: 16px;
  margin-bottom: 12px;

  /* parent uses flexbox: this defines minimum width for translated text "noResults" */
  flex-basis: 100%;
  flex-shrink: 0;
}

.loadingResults {
  composes: h4 from global;
  line-height: 20px;

  /* Cover parent element's space*/
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;

  /* Layout */
  padding: 9px 24px 0 24px;
  margin: 0;
  background-color: var(--matterColorBright);

  @media (--viewportLarge) {
    padding: 9px 36px 0 36px;
  }
}

.resultsFound {
  white-space: nowrap;
}

.searchFiltersPanelClosed {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #4E5155;
  padding: var(--marketplaceButtonSmallDesktopPadding);
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;
  background: #FFFFFF;
  border: 1px solid #D4D4D7;
  cursor: pointer;

  &:hover {
    border: 1px solid #3c5b81;
  }

  &:focus {
    outline: none;
    background-color: var(--matterColorLight);
    border-color: transparent;
    text-decoration: none;
    box-shadow: var(--boxShadowFilterButton);
  }
}

.searchFiltersPanelOpen {
  composes: button marketplaceSearchFilterLabelFontStyles buttonText buttonBorders buttonColors from global;
  /* letter-spacing: 0.2px;
  font-weight: var(--fontWeightSemiBold); */
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: inline-block;
  padding: 9px 16px 9px 16px;
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;
  border: 1px solid var(--marketplaceColor);
  filter: unset;
  letter-spacing: normal;

  &:hover,
  &:focus {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: normal;
    filter: unset;
    /* border: 1px solid var(--marketplaceColorDark); */
  }
}

.localHiring {
  display: flex;
  align-items: center;
  gap: 14px;
  margin-left: 10px;
  margin-bottom: 8px;

  & input {
    width: 15px;
    height: 15px;
    display: block;
  }
}