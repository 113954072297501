@import '../../styles/customMediaQueries.css';

/* Booking form inside modalContainer needs special handling */
/* since "inMobile" breakpoint is actually --viewportLarge */
.modalContainer {
  composes: marketplaceModalInMobileBaseStyles from global;

  height: 100vh;

  @media (--viewportMedium) {
    flex-basis: 576px;
    height: unset;
    padding: var(--modalPaddingMedium);
    background-color: var(--matterColorLight);
    margin-top: 12.5vh;
    margin-bottom: 12.5vh;
  }

  @media (--viewportLarge) {
    padding: 0;
    background-color: transparent;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.modalHeading {
  margin-top: 89px;
  margin-bottom: 36px;
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.title {
  /* Font */
  color: var(--matterColor);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 5px;
  }
}

.author {
  width: 100%;
  composes: h4 from global;
  margin-top: 7px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    color: var(--matterColor);
  }
}

.bookingHeading {
  display: none;

  @media (--viewportLarge) {
    display: block;
    /* margin-bottom: 30px; */
    background-color: #3c5b81;
    padding: 22px 30px;
  }
}

.desktopPriceContainer {
  display: none;

  @media (--viewportLarge) {
    /* Layout */
    display: flex;
    flex-direction: row;
    /* align-items: flex-end; */
    flex-shrink: 0;
    margin: 0 48px 0 0;
    padding: 0;
    flex-wrap: wrap;
    column-gap: 5px;
  }
}

.desktopPriceValue {
  /* Font */
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  color: #ffffff;

  @media (--viewportMedium) {
    margin-top: 0;
  }
}

.desktopPerUnit {
  /* Font */
  /* composes: h5 from global; */
  /* color: #fff; */
  /* margin-bottom: 0;
  margin-top: 3px; */
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  color: #ffffff;

  @media (--viewportMedium) {
    /* margin-left: 6px; */
  }
}

.bookingHeadingContainer {
  margin-top: 9px;

  & h2 {
    font-family: 'Source Serif Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.2625px;
    color: #ffffff;
  }
}

.bookingTitle {
  /* Font */
  composes: h3 from global;
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 2px;
}

.bookingHelp {
  composes: h5 from global;
  display: none;

  @media (--viewportMedium) {
    color: var(--matterColor);
    display: block;
    margin-top: 0;
  }
}

.bookingForm {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
  height: 100%;

  &>div {
    &:nth-child(1) {
      /* padding: 39px 30px 0; */

      &:nth-child(1) {
        &>div {
          &:nth-child(1) {
            margin-top: 0;
          }
        }
      }
    }
  }

  @media (--viewportMedium) {
    padding: 39px 30px;
    min-height: 400px;
  }

  @media (--viewportLarge) {
    min-width: 312px;
    min-height: auto;
  }
}

.submitButtonWrapper {
  flex-shrink: 0;
  /* padding: 0 30px; */
  width: 100%;
  /* position: fixed; */
  bottom: 0;
  background-color: var(--matterColorLight);
  left: 0;
  z-index: 999;
  margin-top: 10px;

  @media (--viewportMedium) {
    width: 100%;
    position: static;
    bottom: unset;
    background-color: transparent;
  }
}

.openBookingForm {
  /* Ensure that mobile button is over Footer too */
  z-index: 9;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 18px 24px 18px 16px;
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadowTop);
  display: flex;

  /* Contain repainting to this component only */
  /* 3D painting container helps scrolling */
  transform: translate3d(0, 0, 0);

  @media (--viewportMedium) {
    padding: 18px 60px 18px 60px;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.priceContainer {
  /* Layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  margin-right: 22px;
  padding: 5px 12px;
}

.priceValue {
  /* Font */
  composes: h2 from global;
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 0px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 0px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.bookButton {
  composes: button buttonFont buttonText buttonBorders buttonColorsPrimary from global;

  /* Clear padding that is set for link elements looking like buttons */
  padding: 0;
}

.closedListingButton {
  border-left: 1px solid var(--matterColorNegative);
  width: 100%;
  padding: 15px 24px 15px 24px;
  text-align: center;
}

.pendingIsApproved {
  margin: 30px;
}