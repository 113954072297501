.breadcrumb {
  display: flex;
  align-items: center;

  @media (max-width: 767px) {
    display: none;
  }

  &>a {
    font-family: 'Avenir LT Std';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 1.25px;
    text-transform: capitalize;
    color: #595858;
  }

  &>a:hover {
    text-decoration: none;
    cursor: pointer;
  }

  &>span {
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 1.25px;
    text-transform: capitalize;
    color: #939393;
  }
}

.breadcrumbIcon {
  display: flex;
  align-items: center;
  margin: 0 5px;
  opacity: 0.8;
}