@import '../../styles/customMediaQueries.css';

.root {
  composes: h4 from global;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #FF0000;
}