@import '../../styles/customMediaQueries.css';

.inputWrapper {
  & .inputSelect {
    margin-bottom: 38px;

    & label {
      font-family: 'Source Sans Pro';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #6c6d7a;
    }

    & select {
      font-family: 'Source Serif Pro';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 32px;
      color: #8f908d;
    }
  }
}

.requirementDescription {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #7d7d7d;
  margin-bottom: 48px;
  margin-top: 0;
}

.radioButton {
  & p {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #6c6d7a;
    margin-top: 0;
    margin-bottom: 14px;
  }

  & .radioRow {
    display: grid;
    margin-top: 0;
    margin-bottom: 53px;
    grid-template-columns: repeat(2, 1fr);
  }

  & span {
    font-family: 'Source Serif Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    letter-spacing: -0.0888889px;
    color: #000000;
  }
}

.buttonBox {
  display: flex;
  gap: 20px;
  margin-top: 40px;
}

.blueButton {
  & svg {
    margin-right: 10px;
  }
}

.primaryButton {
  & svg {
    margin-left: 10px;
  }
}

.secondSection {
  & .imagesSet {
    margin-top: 40px;
    margin-bottom: 37px;

    & .headingImage {
      margin-top: 0;
      font-family: 'Source Sans Pro';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      color: #6c6d7a;
      margin-bottom: 10px;
    }
  }

  & .fieldTextarea {
    background: #fff;
    border: 1px solid #939393;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 20px;
    position: relative;

    &>div {
      position: absolute;
      margin-top: 25px;
      left: 0;
    }

    & textarea {
      height: 104px !important;
      overflow-y: auto !important;
      font-family: 'Source Sans Pro';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0.01em;
      color: #212121;
      border: none;

      &::-webkit-scrollbar {
        width: 7px;
      }

      &::-webkit-scrollbar-thumb {
        border: 1px solid rgb(255 30 30 / 0%);
        background-clip: padding-box;
        border-radius: 9999px;
        background-color: #939393;
        margin-right: 10px;
      }

      &::-webkit-scrollbar-track {
        background: #ededed;
        border-radius: 10px;
        margin-right: 20px;
      }
    }

    & label {
      font-family: 'Source Sans Pro';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px !important;
      color: #6c6d7a;
    }
  }
}

.descriptionHeading {
  font-family: Source Sans Pro;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  color: #6C6D7A;
  padding-bottom: 6px;
}



.thirdSection {
  & .inputBox {
    margin-bottom: 23px;

    & textarea {
      padding: 0 0 6px 0;
    }

    & select {
      padding: 0 0 6px 0;
    }

    & label {
      font-family: 'Source Sans Pro';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #6c6d7a;
    }
  }

  & .inputRow {
    display: flex;
    justify-content: space-between;

    & .inputBox {
      width: calc(50% - 9px);
    }
  }

  & .inputCheckbox {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 17px;
    margin-bottom: 28px;

    & .inputBox {}
  }
}

.termServices {
  color: #da8240;
}

.authenticationButtons {
  display: flex;
  flex-direction: column;
  gap: 26px;

  & button {
    background: #ffffff;
    border: 2px solid #40526e;
    box-shadow: 0px 4px 4px rgba(64, 82, 110, 0.25);
    border-radius: 100px;
    font-family: 'Source Serif Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 56px;
    letter-spacing: 1px;
    color: #000000;
    min-height: 74px;

    @media(max-width: 900px) {
      font-size: 16px;
    }
  }
}

.root {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
}

.imagesField {
  flex-shrink: 0;
  margin: 0;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;

  &>div {
    width: 118px;
    height: 118px;
  }
}

.thumbnail {
  /* margin: 24px 0 0 0; */

  @media (--viewportLarge) {
    width: calc(50% - 12px);
    margin: 0 24px 24px 0;

    &:nth-child(even) {
      margin: 0 0 24px 0;
    }
  }
}

.addImageWrapper {
  float: left;
  position: relative;
  margin: 0;
  overflow: hidden;
  width: 118px;
  height: 118px;

  @media (max-width: 768px) {
    /* padding: 24px 0 24px 0; */
  }

  @media (max-width: 1024px) {
    /* margin-top: 24px; */
  }

  &::after {
    content: '.';
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }
}

.thumbnailLoading {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectRatioWrapper {
  padding-bottom: calc(100% * (2 / 3));
}

.addImage {
  /* Layout */
  /* Maintain aspect ratio */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;

  border-radius: 2px;
  background: #f2f2f2;
  border: 2px dashed #e6e6e6;
  /* Behaviour */
  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    border-color: var(--matterColorAnti);
  }
}

.chooseImageText {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chooseImage {
  composes: h4 from global;
  color: var(--matterColor);
  font-weight: var(--fontWeightSemiBold);

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    font-weight: var(--fontWeightSemiBold);
    margin-top: 0;
    margin-bottom: 0;
  }
}

.imageTypes {
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.addImageInput {
  display: none;
}

.imageRequiredWrapper {
  width: 100%;
  clear: both;
}

.tip {
  composes: h5 from global;

  flex-shrink: 0;
  color: var(--matterColorAnti);
  margin-top: 25px;
  margin-bottom: 60px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.0777778px;
  color: #4e5155;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 85px;
  }
}

.error {
  color: var(--failColor);
}

.submitButton {
  flex-shrink: 0;
  margin-top: auto;
  margin-bottom: 24px;
  width: 241px;

  @media (--viewportLarge) {
    display: inline-block;
    /* margin-top: 100px; */
  }

  @media (max-width: 768px) {
    margin-bottom: 50px;
  }

  @media (max-width: 460px) {
    width: 100%;
  }
}




.locationAddress,
.building {
  flex-shrink: 0;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.locationAutocompleteInput {
  flex-grow: 1;
  height: 36px;
  line-height: unset;
  padding-left: 0;
  margin: 0;
  border-bottom: 2px solid var(--attentionColor);
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  /* identical to box height, or 200% */

  letter-spacing: -0.0888889px;

  color: #4A4A4A;

  &:hover,
  &:focus {
    border-bottom-color: var(--matterColorDark);
    outline: none;
  }

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  @media (--viewportMedium) {
    height: 40px;
  }
}

.locationAutocompleteInputIcon {
  display: none;
}

.predictionsRoot {
  position: absolute;
  width: 100%;
  padding-bottom: var(--locationAutocompleteBottomPadding);
  top: 36px;
  left: 0;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);

  @media (--viewportMedium) {
    top: 40px;
  }
}

.validLocation {
  border-bottom-color: var(--successColor);
}

.threeToTwoWrapper {
  position: relative;
}

.removeImage {
  position: absolute;
  top: 0;
  right: 0;
  width: 33px;
  height: 33px;
  padding: 0;
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 0;
  border-bottom-left-radius: 2px;
  cursor: pointer;

  & svg {
    position: absolute;
    top: 12px;
    left: 12px;
    width: 10px;
    height: 10px;
    fill: var(--matterColorAnti);
    stroke: var(--matterColorAnti);
  }

  &:hover svg {
    fill: var(--matterColorLight);
    stroke: var(--matterColorLight);
  }
}

.aspectWrapper {
  padding-bottom: calc(100% * (2 / 3));

  & video {
    height: 118px !important;
  }
}

.missingInformationModal {
  composes: marketplaceModalBaseStyles from global;
  padding-top: 26px;
}

.termsLink {
  composes: marketplaceModalHelperLink from global;
  color: #da8240;
  font-size: 16px;
    font-style: normal;
    font-weight: 400;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.bottomWrapperText {
  composes: marketplaceModalBottomWrapperText from global;
  margin: 0;
  margin-bottom: 39px;
}

.termsText {
  composes: marketplaceModalHelperText from global;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #585858;

  &>span {
    &span {
      &>span {
        margin-left: 5px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        text-decoration: underline;
        line-height: 24px;
        font-family: 'Source Sans Pro';
        text-align: center;
        color: #4895ba;
      }
    }
  }
}


.disable {
  margin-bottom: 22px;
  cursor: not-allowed;
  /* opacity: 0.3; */
  background-color: var(--matterColorNegative);
  color: var(--matterColorLight);
  filter: unset;
}

.disable:hover{
  background-color: var(--matterColorNegative);
  color: var(--matterColorLight);
}