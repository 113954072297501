@import '../../styles/customMediaQueries.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex-grow: 1;
  flex-direction: column;

  padding-top: 1px;
}

.title,
.heading,
.subheading,
.timezonePicker,
.weekDay,
.hasEntries,
.submitButton {
  padding-left: 25px;
  padding-right: 25px;

  @media (--viewportSmall) {
    padding-left: 60px;
    padding-right: 60px;
  }
}

.subheading {
  margin: 0;
  padding-top: 19px;
  padding-bottom: 5px;
  color: var(--matterColorAnti);
}

.error {
  color: var(--failColor);
}

.timezonePicker {
  margin-bottom: 24px;
}

.week {
  display: flex;
  flex-direction: column;

  padding-top: 0;
  margin-bottom: 23px;

  @media (--viewportLarge) {
    margin-bottom: 95px;
  }
}

.weekDay {
  composes: marketplaceTabNavHorizontalFontStyles from global;

  /* Layout */
  display: flex;
  flex-direction: row;
  padding-top: 16px;
  padding-bottom: 19px;
  border-top: solid 1px var(--matterColorNegative);
 

  &:last-child {
    border-bottom: solid 1px var(--matterColorNegative);
  }
}

.hasEntries {
  background-color: var(--matterColorLight);
}

.dayOfWeek {
  width: 150px;
  /* padding-top: 8px; */
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #4a4a4a;
  margin-left: 10px;

  @media (--viewportSmall) {
    /* width: 200px;` */
  }
}

.timePicker {
  width: 100%;
}

.fieldWrapper {
  /* This container uses flexbox layout */
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  /* parent aka root is flexbox, this container takes all available space */
  flex-grow: 1;
  flex-shrink: 0;
  flex-wrap: nowrap;
  padding-bottom: 6px;

  position: relative;
  
  @media(max-width: 480px) {
  display: block;
  }

  &::before {
    content: '';
    position: absolute;
    top: 41px;
    left: 0;
    width: 100%;
    border-bottom: 1px solid var(--matterColorNegative);
    z-index: 0;
  }
}

.formRow {
  /* This container uses flexbox layout */
  display: flex;
  justify-content: flex-start;
  align-items: center;

  /* parent aka fieldWrapper is flexbox, this container takes all available space */
  flex-grow: 1;
  flex-shrink: 0;
  flex-wrap: nowrap;

  z-index: 1;
}

.closeIcon {
  composes: marketplaceModalCloseIcon from global;

  stroke: none;
  fill: var(--matterColorAnti);

  &:hover {
    fill: var(--matterColor);
  }

  /* align icon with the select fields */
  margin-left: 6px;
  padding: 6px 0  11px;
}

.dashBetweenTimes {
  padding-left: 6px;
  padding-right: 6px;
  padding-bottom: 8px;
}

.buttonSetHours {
  padding-top: 8px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-decoration-line: underline;
  color: #40526e;
}

.buttonAddNew {
  composes: h5 from global;
  color: var(--matterColor);
}

.submitButton {
  margin-top: auto;
  flex-shrink: 0;
  /* Mobile phones introduce bottom-bar, for which we need to give 96px vertical space */
  padding-bottom: 96px;
}

@media (--viewportMedium) {
  .root {
    padding-top: 2px;
    margin-top: -16px;
  }

  .title {
    margin-bottom: 32px;
  }

  .buttonAddNew {
    margin-bottom: 6px;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 1px;
    text-decoration-line: underline;
    color: #40526e;
  }

  .submitButton {
    padding-bottom: unset;
  }
}

@media (--viewportLarge) {
  .calendarWrapper {
    flex-grow: 0;
    margin-bottom: 24px;
  }
}

.fieldSelect {
  composes: marketplaceTabNavHorizontalFontStyles from global;

  background: none;
  padding-right: 0;
  /* padding-top: 8px; */
  padding-bottom: 8px;
  width: 42px;

  &:select {
    margin-right: 0;
  }
}

.entriesCheck {
 

  & label {
    width: 26px;
  }

  @media(max-width: 768px) {
    margin-top: 8px;
  }
}

.fieldArrayRemove {
  position: absolute;
  right: -26px;
  top: 0;

  @media(max-width: 768px) {
    position: absolute;
    right: 0;
    top: -30px;
  }

  @media(max-width:506px) {
    position: absolute;
    right: -18px;
    top: -20px;
  }

  @media(max-width:480px) {
    position: absolute;
    right: -2px;
    top: -50px;
  }
}


.timeWeek {

  & select {
    padding-top: 0;
    width: 80px;
  }
}

.applyDaysWrapper {
  position: relative;
}

.headingName {
  font-family: 'Source Serif Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 45px;
  letter-spacing: -0.9px;
  color: #4a4a4a;
  margin-bottom: 33px;
  padding-left: 30px;
  padding-right: 30px;

  @media (--viewportSmall) {
    padding-left: 60px;
    padding-right: 60px;
  }
}

.subheadingOperation {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.1125px;
  color: #40526e;
  margin-bottom: 10px;
}

.headingLine {
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (--viewportSmall) {
    padding-left: 60px;
    padding-right: 60px;
  }
}

.applyDays {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-decoration-line: underline;
  color: #40526e;
  cursor: pointer;
  @media (max-width: 480px) {
  margin-top: 8px;
  }
}