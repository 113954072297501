@import '../../styles/customMediaQueries.css';

.root {
  position: relative;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: var(--matterColorNegative);

  width: 100%;
  padding: 24px 24px 42px 24px;
  background: #152734;

  @media (--viewportMedium) {
    padding: 0 24px 33px 24px;
  }

  @media (--viewportLarge) {
    padding: 0 0 81px 0;
    border-top: none;
  }
}

.whiteBackground {
  position: relative;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: var(--matterColorNegative);

  width: 100%;
  padding: 24px 24px 42px 24px;
  background: #fcfcfc;

  @media (--viewportMedium) {
    padding: 0 24px 33px 24px;
  }

  @media (--viewportLarge) {
    padding: 0 0 81px 0;
    border-top: none;
  }

  & .organizationDescription {
    color: #4a4a4a;
  }

  & .link {
    color: #4a4a4a;
  }
}

/* Wrapper to limit the top border in desktop
 * view from the outermost paddings*/
.topBorderWrapper {
  @media (--viewportMedium) {
    padding-top: 41px;
  }

  @media (--viewportLarge) {
    padding-top: 73px;

    border-top-style: solid;
    border-top-width: 1px;
    border-top-color: var(--matterColorNegative);
  }
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (--viewportMedium) {
    margin: 0 auto;
  }

  @media (--viewportLarge) {
    max-width: 1128px;
    padding: 0 36px;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 0;
  }
}

/* Mobile only social media links */
.someLiksMobile {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    display: none;
  }
}

.icon {
  display: inline-block;
  width: 20px;
  height: 24px;
  margin-right: 12px;
  text-align: center;

  & svg {
    fill: #fff;
  }
}
.whiteBackground {
  & .icon:not(:last-child) {
    & svg {
      fill: #000;
    }
  }
}

/* Footer links */
.links {
  /* Layout */
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 18px;

  @media (--viewportMedium) {
    flex-wrap: nowrap;
    /* justify-content: space-between; */
    margin-bottom: 0;
  }
}

/* Desktop: logo and organization stuff */
.organization {
  flex-shrink: 0;
  flex-basis: 220px;
  /* display: none; */
  margin-right: 0px;
  margin-bottom: 0;
  /* text-align: center; */

  @media (--viewportMedium) {
    display: block;
    /* order: 1; */
    margin-right: 12px;
    flex: 2 !important;
    & > * {
      max-width: 70%;
      margin: 0 auto;
    }
  }

  @media (--viewportLarge) {
    flex-basis: 240px;
    margin-right: 57px;
  }

  @media (--viewportLargeWithPaddings) {
    flex-basis: 250px;
    margin-right: 12px;
  }
  @media (max-width: 768px) {
    width: 50%;
    order: 3;
    /* margin: auto; */
    flex-basis: unset;
    /* text-align: left; */
    /* margin-right: 0; */
    margin-bottom: 0px;
  }
}

.withLogin {
  flex-shrink: 0;
  flex-basis: 220px;
  /* display: none; */
  margin-right: 0px;
  margin-bottom: 0;
  text-align: center;

  @media (--viewportMedium) {
    display: block;
    /* order: 1; */
    margin-right: 12px;
    flex: 2 !important;
    & > * {
      max-width: 70%;
      margin: 0 auto;
    }
  }
  

  @media (--viewportLarge) {
    flex-basis: 240px;
    margin-right: 57px;
  }

  @media (--viewportLargeWithPaddings) {
    flex-basis: 250px;
    margin-right: 12px;
  }

  @media (max-width: 768px) {
    width: 50%;
    order: 3;
    /* margin: auto; */
    flex-basis: unset;
    /* text-align: left; */
    /* margin-right: 0; */
    margin-bottom: 0px;
  }
}

.logoLink {
  display: block;
  /* margin-bottom: 14px; */
}

.logo {
  object-fit: contain;
  object-position: left center;
  width: 100%;
  height: 27px;
  @media (max-width: 768px) {
& img{
  width: 50%;
}
  }
}

.footerLinksWrapper {
  /* display: grid; */
  /* grid-template-columns: repeat(4, 1fr); */
  width: 100%;
  display: flex;
  gap: 20px;
  & > div {
    flex: 1;
    @media (max-width: 768px) {
      width: calc(50% - 20px);
      min-width: calc(50% - 20px);
      max-width: calc(50% - 20px);

    }
  }
  @media (max-width: 767px) {
   flex-wrap: wrap;
   justify-content: space-between;
  }
  @media (max-width: 576px) {
    /* grid-template-columns: repeat(2, 1fr); */
    gap: 20px;
  }
}

.organizationInfo {
  @media (max-width: 768px) {
    padding-left: 24px;
    margin-top: 15px;
    padding-left: 0;
  }
}

.organizationDescription,
.organizationCopyright {
  composes: marketplaceTinyFontStyles from global;
  color: #fff;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.1px;
  color: #ffffff;
}

.organizationCopyright {
  margin: 0;
  margin-top: 6px;
}

.copyrightLink {
  color: #fff;
  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }
}
.copyright {
  color: #4a4a4a;
  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }
}

/* Desktop and Mobile: links to more information */
.infoLinks {
  /* order: 2; */
  flex-basis: 165px;

  @media (--viewportMedium) {
    padding-top: 2px;
    /* margin-right: 12px;
    margin-right: 24px; */
    margin-bottom: 24px;
  }

  @media (max-width: 768px) {
    flex-basis: 100%;
  }
}

.list {
  margin-top: 0;
  margin-bottom: 0;
}

.listItem {
  min-height: 24px;
  margin-bottom: 6px;

  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}

.link {
  /* Font */
  composes: h5 from global;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
  transition: var(--transitionStyleButton);

  margin-top: 0;
  margin-bottom: 12px;

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }

  @media (max-width: 768px) {
    font-size: 14px;
  }
}

/* Desktop and Mobile: searches - first column */
.searches {
  /* order: 3; */
  flex-basis: 165px;
  margin-right: 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    padding-top: 2px;
    margin-right: 12px;
  }

  @media (max-width: 768px) {
    flex-basis: 100%;
  }
}

/* Desktop and Mobile: searches - second column */
/* This is not always visible */
.searchesExtra {
  /* order: 4; */
  flex-basis: 165px;
  margin-right: 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    padding-top: 2px;
    margin-right: 12px;
  }

  @media (max-width: 768px) {
    flex-basis: 100%;
  }
}

/* Desktop: extra links (social media links and legal links) */
.extraLinks {
  flex-shrink: 0;
  /* order: 5; */
  /* display: none; */
  flex-basis: 109px;

  @media (--viewportMedium) {
    margin-bottom: 24px;
    margin-right: 24px;
    display: flex;
    flex-direction: column;
    margin-right: 12px;
    align-items: center;
  }

  @media (--viewportLarge) {
    margin-right: 0;
  }
  @media (max-width: 768px) {
    order: 4;
  }
  
}

.legalMatters {
  /* Get extra space from parent element (which is using flexbox) */

  /* Layout */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: 10px;
  @media (--viewportLarge) {
    margin-top: 10px;
  }
}

.tosAndPrivacy {
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-bottom: 10px;
  }
}

.legalLink,
.privacy,
.terms {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  letter-spacing: -0.1px;
  color: #fff;
  transition: var(--transitionStyleButton);

  margin-top: 0;
  margin-bottom: 0;

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.whiteBackground {
  & .legalLink,
  & .privacy,
  & .terms {
    color: #4a4a4a;
  }
}

/* Mobile: copyright mark and legal links for mobile */
.copyrightAndTermsMobile {
  /* Positioning */
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  /* Dimensions */
  height: 60px;
  padding: 0 24px 18px 24px;

  /* Layout */
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    display: none;
  }
}

.organizationCopyrightMobile {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColorAnti);
  transition: var(--transitionStyleButton);

  /* Tell parent component how much space this one prefers */
  flex-basis: 172px;

  margin-top: 0;
  margin-bottom: 0;

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }
}

.tosAndPrivacyMobile {
  flex-basis: 172px;
}

.privacy {
  margin-right: 24px;
}