.cropContainer {

  /* position: relative;
    width: 80%;
    height: 300;
    background: #333; */
  &>div:first-child {
    top: 60px;

    /* &>div {
      box-shadow: none;
      border: 5px solid rgb(255 255 255);
    } */
  }
}

.cropButton {
  position: absolute;
  margin-bottom: 40px;
  left: 50%;
  bottom: 0;
  transform: translate(-50%,0);
  background-color: var(--marketplaceColor);
  border-radius: 10px;
  font-weight: var(--fontWeightBold);
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  color: var(--matterColorLight);
  padding: 16px 24px;
  cursor: pointer;
  width: 200px;



  /* &:hover {
    background-color: #000;
  } */
}
