.root {}

.password {
  composes: marketplaceModalPasswordMargins from global;
  margin-bottom: 150px;
  margin-top: 40px;

  @media(max-width: 1024px) {
    margin-bottom: 24px;
  }
}