@import '../../styles/customMediaQueries.css';

.root {
  flex-grow: 1;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 11px 24px 0 24px;
}

.form {
  flex-grow: 1;
}

/* ================ Modal ================ */

.modalHeaderWrapper {
  margin-top: 58px;
  margin-bottom: 36px;
  padding: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 34px;
    padding: 0;
  }
}

/* ================ Typography ================ */

/* Title of the modal */
.modalTitle {
  composes: marketplaceModalTitleStyles from global;
}

/* Paragraph for the Modal */
.modalMessage {
  composes: marketplaceModalParagraphStyles from global;
}

/* This is the title for the Edit Photos component */
.title {
  margin-bottom: 17px;
  padding: 0;
  font-family: 'Source Serif Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 56px;
  letter-spacing: -1.25px;
  color: #4A4A4A;

  @media (max-width: 768px){
    font-size: 32px;
    line-height: 25px;
  }
}

.subTitle {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #40526E;
  margin-bottom: 42px;
}