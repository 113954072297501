.root {
  /* Adding empty .root class to enforce className overwrite */
}

/* Title of the modal */
.modalTitle {
  composes: marketplaceModalTitleStyles from global;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 40px;
  letter-spacing: -0.9px;
  color: #4a4a4a;
  font-family: 'Source Sans Pro';
}

/* Paragraph for the Modal */
.modalMessage {
  composes: marketplaceModalParagraphStyles from global;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  letter-spacing: -0.0888889px;
  color: #4a4a4a;
  font-family: 'Source Sans Pro';
}

/* Make the email pop */
.email {
  font-weight: var(--fontWeightHighlightEmail);
}

.helperLink {
  composes: a from global;
  composes: marketplaceModalHelperLink from global;
}

.helperText {
  composes: marketplaceModalHelperText from global;
}

.error {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;

  /* Align the helper links to the left since there isn't a Primary Button anymore */
  text-align: left;
}

.modalIcon {
  composes: marketplaceModalIconStyles from global;
}

.reminderModalLinkButton {
  composes: button buttonFont buttonText buttonBorders buttonColorsPrimary from global;
}

.termsWrapper {
  & > div {
    background-color: #fff !important;
  }

  & h2 {
    font-size: 22px;
  }

  & p {
    font-size: 14px;
  }
}

.termsModal{
  & > div{
    & > div{
      @media (min-width: 768px){
        margin-top: 40px;
      }
    }
  }
}

.termsWrapper {
  max-height: 800px;
  overflow-y: auto;
  padding-right: 15px;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
    background: #ddd;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: #f6861f;
    border-radius: 6px;
    outline: 1px solid #fff;
  }
}

.buttonScroll {
  position: absolute;
  bottom: 10px;
  left: 37%;
  z-index: 2;
  display: inline-block;
  cursor: pointer;
  color: #fff;
  font: normal 400 20px/1 'Josefin Sans', sans-serif;
  letter-spacing: 0.1em;
  text-decoration: none;
  transition: opacity 0.3s;
  padding-top: 50px;
  & span {
    position: absolute;
    top: 0;
    left: 50%;
    width: 46px;
    height: 46px;
    margin-left: -23px;
    border: 2px solid #fff;
    border-radius: 100%;
    box-sizing: border-box;
    &:after {
      position: absolute;
      top: 50%;
      left: 50%;
      content: '';
      width: 16px;
      height: 16px;
      margin: -12px 0 0 -8px;
      border-left: 4px solid #fff;
      border-bottom: 4px solid #fff;
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
      box-sizing: border-box;
    }
  }
}

.termsMainWrapper {
  position: relative;
  &.blackShadow {
    &:after {
      position: absolute;
      bottom: 0;
      left: 0;
      content: '';
      width: 100%;
      height: 10%;
      background: -webkit-linear-gradient(
        top,
        rgba(0, 0, 0, 0) 0,
        rgba(0, 0, 0, 0.6) 95%,
        rgba(0, 0, 0, 0.5) 100%
      );
      background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0,
        rgba(0, 0, 0, 0.6) 95%,
        rgba(0, 0, 0, 0.5) 100%
      );
    }
  }
}
