@import '../../styles/customMediaQueries.css';

.root {
  flex-grow: 1;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 11px 24px 0 24px;
}

.form {
  flex-grow: 1;
}

.title {
  margin-bottom: 11px;
  margin-top: 0;
  font-family: 'Source Serif Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 56px;
  letter-spacing: -1.25px;
  color: #4A4A4A;

  @media (max-width: 768px){
    font-size: 32px;
    line-height: 50px;
  }
}