.rightSide {
  margin-left: 0;
  width: 100%;
  height: 100%;
  transition: all 300ms cubic-bezier(0.19, 1, 0.56, 1);
  background-color: var(--matterColorLight);
  position: relative;
  height: calc(var(--vh) * 100);
  top: -30px;
  left: 0;
  z-index: 2101;
  padding: 30px;

  @media(--viewportXLarge) {
    height: 85vh;
  }

  @media (--viewportMedium) {
    z-index: 10000;
    max-width: 400px;
    padding: 0 16px;
    width: 100%;
    /* position: fixed; */
    right: 0;
    top: 0;
    background: #fff;
    box-shadow: 0px 0 5px #dddddd;
  }
  @media (max-width:767px) {
    top: 0;
    padding: 20px 10px;
  }

  & .cross {
    font-size: 30px;
    font-weight: 300;
    position: absolute;
    top: 10px;
    right: 15px;
    cursor: pointer;

    @media (max-width:767px) {
      top: 30px;
      right: 30px;
    }

    &:hover {
      color: var(--marketplaceColorLight);
    }
  }

  &.show {
    display: block;
    transform: translateX(0);
  }

  & .messageInputArea {
    display: flex;
    align-items: center;
    padding: 10px 0 0;

    @media (--viewportMedium) {
      padding: 10px 0;
    }

    &>svg {
      width: 20px;
      margin-right: 10px;

      &.smile {
        width: 18px;
      }

      &:hover {
        cursor: pointer;
        fill: var(--marketplaceColorLight);

        &>path {
          fill: var(--marketplaceColorLight);
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }

    & .msgArea {
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: solid 1px var(--matterBorderColor);
      padding: 0 10px;
      border-radius: 4px;

      &>input {
        flex-grow: 1;
        padding: 0;
        border: none;
        padding-right: 10px;
        height: 40px;
        font-size: 14px;
        font-weight: 400;
      }

      & .sendbtn {
        border: none;
        padding: 0px;
        background-color: none;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        padding: 6px;
        border-radius: 100px;
        width: auto;
        box-sizing: border-box;

        &>svg {
          width: 16px;
          height: 16px;
          margin-bottom: -1px;
          margin-left: -1px;
        }

        &:hover {
          &>svg {
            stroke: var(--matterColorLight);
          }
        }
      }
    }
  }
}



.chatContainer {
  background-color: var(--chat-background);
  display: flex;
  flex-direction: column;
  height: 100%;

  @media (max-width:767px) {
    height: calc(100vh - 60px);
  }
}


.chatArea {
  flex: 1;
  overflow-y: auto;
  padding: 16px 0;

  @media (max-width:767px) {
    padding: 0 0 16px 0;
  }

  &>button {
    border: none;
    outline: none;
    box-shadow: none;
    padding: 0;
    margin: 0 0 20px 0;
    font-size: 16px;
    font-weight: 600;
    width: auto;
  }
}

.messageWrapper {
  display: flex;
  margin-bottom: 14px;

  & .profilePicture {
    width: 32px;
    height: 32px;
    border-radius: 100px;
    background-color: var(--matterColorAnti);
    margin-right: 10px;
  }

  & .ownMessageContent {
    & img {
      width: 35px;
      height: 35px;
      border-radius: 50%;
    }

    &::before {
      position: absolute;
      left: -18px;
      top: 0px;
      content: '';
      border-top: solid 12px transparent;
      border-bottom: solid 10px transparent;
      border-left: solid 10px transparent;
      border-right: solid 12px var(--matterColorNegative);
    }
  }

  & .messageContent {
    width: 100%;
    position: relative;

    &::before {
      display: none;
      position: absolute;
      left: -18px;
      top: 0px;
      content: '';
      border-top: solid 12px transparent;
      border-bottom: solid 10px transparent;
      border-left: solid 10px transparent;
      border-right: solid 12px rgb(252 246 243);
    }

    &>p {
      margin: 0 0 5px 0;
      font-size: 14px;
      color: var(--marketplaceColorLight);
      line-height: 20px;
    }

    & .msg {
      font-size: 14px;
      line-height: 20px;
      background-color: #faf4f4;
      padding: 6px 10px;
      border-radius: 10px;
      display: inline-block;
    }

    & .date {
      font-size: 10px;
      line-height: 14px;
      width: 90px;
      text-align: right;
    }
  }
}

.messageBoxWrapper {
  display: flex;
  gap: 10px;

  &>a {
    background-image: none;
  }

  & img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
  }

  & .messageBodyBox {
    width: 100%;

    & .rightBody {
      display: flex;
      /* align-items: center; */
      justify-content: space-between;
      /* margin-bottom: 5px; */

      & h4 {
        font-size: 12px;
        line-height: 18px;
        margin-bottom: 2px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }
  }
}

.shareMeetingLinkSec {
  width: 100%;
  max-width: 360px;
  border-radius: 10px;
  background-color: var(--matterColorLight);
  padding: 14px;
  position: absolute;
  bottom: 6px;
  left: 6px;
  box-shadow: 0px 0px 11px #33333338;
  z-index: 1;

  @media (--viewportSmall) {
    bottom: 10px;
    left: 10px;
  }

  @media (--viewportMedium) {
    bottom: 30px;
    left: 30px;
  }

  &>h2 {
    font-size: 16px;
    font-weight: 600;
    margin: 0 0 10px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &>span {
      font-size: 30px;
      font-weight: 300;
      line-height: 20px;
      cursor: pointer;

      &:hover {
        color: var(--matterColorDark);
      }
    }
  }

  &>p {
    font-size: 14px;
    line-height: 130%;
    font-weight: 400;
  }

  & .copyLinkSec {
    background-color: var(--matterBgColorLight);
    border-radius: 4px;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &>span {
      /* @apply --marketplaceH5FontStyles; */
      margin: 0;
      display: -webkit-inline-box;
      overflow: hidden;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      width: 80%;
    }

    &>svg {
      cursor: pointer;

      &:hover {
        fill: var(--matterColorDark);
      }
    }
  }
}