.shareScreenBox {
    width: 56px !important;
    height: 56px !important;
    min-width: 56px !important;
    margin: 0 !important;
    padding: 0;

    @media(max-width: 460px) {
        width: 35px !important;
        height: 35px !important;
        min-width: 35px !important;
    }
}