.root {}

.select {
  color: var(--matterColorAnti);
  border: solid 1px var(--matterColorNegative);
  padding: 0px 15px;
  border-radius: 10px;
  height: 45px;
  padding-right: 40px;

  &>option {
    color: var(--matterColor);
  }

  &>option:disabled {
    color: var(--matterColorAnti);
  }
}

.selectSuccess {
  color: var(--matterColor);
  border-color: var(--successColor);
}

.selectError {
  border-color: var(--failColor);
}

.multiSelectBox {

  /* & :global(#react-select-3-listbox) {
    max-height: 600px !important;
    overflow: hidden;
    position: relative;
    z-index: 1000;
    padding-bottom: 30px;
    & div {
      max-height: 1000px !important;
    }
  } */
  &>div {
    /* background: #FFFFFF; */
    border-radius: 10px;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    /* height: 48px; */
    color: #525961;

    border-color: rgb(82 89 97 / 80%);

    &:not(:last-child) {
      min-height: 61px;
    }

    &>div {
      padding: 5px 8px;
    }

    & input {
      height: auto;
    }
  }

  &>span+div {
    &>div:first-child {
      &>div:not(:last-child) {
        background: linear-gradient(0deg, rgba(85, 66, 246, 0.1), rgba(85, 66, 246, 0.1)), #ffffff;
        border-radius: 45px;
        width: max-content;
        display: inline-flex;
        align-items: center;
        padding: 2px 10px;
        font-size: 16px;

        & div[role="button"] {
          border: 1.5px solid #98a8b7;
          border-radius: 50%;
          height: 20px;
          width: 20px;
        }
      }
    }
  }
}

.parent {
  display: flex;
  border-bottom: 1px solid;
}

.inputWrapper {
  display: flex;
  align-items: center;
  gap: 14px;
  /* height: 18px; */

  & input {
    width: 15px;
    height: 15px;
  }

  & label {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #445463;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 0;
    cursor: pointer;
  }

  & input[type="checkbox"] {
    accent-color: #3a01e2;
  }
}

.selectList {}