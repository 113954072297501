.videoActionButton  {
  background-repeat: no-repeat;
  background-size: 24px;
  border: none;
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
  box-sizing: border-box;
  cursor: pointer;
  outline: none;
  background-color: #e0e0e0;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  min-width: 56px !important;
  height: 56px;
  border-radius: 100px;
  margin: 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media(max-width: 460px) {
    width: 40px;
    height: 40px;
  }

  &>svg {
    width: 25px;

    &>g {
      &>path {
        fill: var(--matterColorDark);
      }
    }

    &>path {
      fill: var(--matterColorDark);
    }

    &.shareScreenIcon {
      width: 18px;

      @media (--viewportMedium) {
        width: 18px;
      }
    }

    &.chatIcon {
      width: 25px;
      fill: none;
      

      @media (max-width:480px) {
        width: 20px;
      }
    }

    &.endCallIcon {
      width: 20px;

      @media (--viewportMedium) {
        width: 23px;
      }

      &>path {
        stroke: var(--matterColorDark);
        fill: none;
      }
    }

    &.whiteBoardIcon {
      width: 18px;

      @media (--viewportMedium) {
        width: 20px;
      }

      &>g {
        &>path {
          fill: var(--matterColorDark);
        }
      }
    }
  }

  &.mic {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' stroke='%232c303a' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-mic-off' viewBox='0 0 24 24'%3E%3Cpath d='M1 1l22 22M9 9v3a3 3 0 005.12 2.12M15 9.34V4a3 3 0 00-5.94-.6'/%3E%3Cpath d='M17 16.95A7 7 0 015 12v-2m14 0v2a7 7 0 01-.11 1.23M12 19v4M8 23h8'/%3E%3C/svg%3E%0A");
    background-position: center;
  }

  &.camera {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' stroke='%232c303a' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-camera-off' viewBox='0 0 24 24'%3E%3Cpath d='M1 1l22 22M21 21H3a2 2 0 01-2-2V8a2 2 0 012-2h3m3-3h6l2 3h4a2 2 0 012 2v9.34m-7.72-2.06a4 4 0 11-5.56-5.56'/%3E%3C/svg%3E%0A");
    background-position: center;
  }
}

.flipActionButton {
  background-repeat: no-repeat;
  background-size: 24px;
  border: none;
  box-shadow: var(--navigation-box-shadow);
  border-radius: 8px;
  cursor: pointer;
  outline: none;
  background-color: #e0e0e0;
  width: 40px;
  height: 56px;
  border-radius: 100px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media(max-width: 460px) {
    width: 40px;
    height: 40px;
  }

  &>svg {
    height: 56px;
  }
}

.showControls {
  background-color: red;
}