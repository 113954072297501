@import '../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.password {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
}

/* Adjust the bottomWrapper's margin to match modals without the helper text */
.bottomWrapperText {
  composes: marketplaceModalBottomWrapperText from global;
  margin-bottom: 14px;
}

.recoveryLink {
  composes: marketplaceModalHelperLink from global;
  color: #B2B2B2;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
}

.recoveryLinkInfo {
  composes: marketplaceModalHelperText from global;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  text-align: center;
  color: #4A4A4A;
}

.signupInputs {
  & input {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    letter-spacing: -0.0888889px;
    color: #4A4A4A;
  }
}

.error {
  color: #FF0000;
}
