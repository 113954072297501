@import '../../styles/customMediaQueries.css';

.root {
  margin: 0;

  /* Clearfix */
  composes: clearfix from global;
}

.messageItem {
  margin-bottom: 20px;

  /* Clearfix */
  composes: clearfix from global;

  @media (--viewportMedium) {
    margin-bottom: 17px;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}

.threeToTwoWrapper {
  position: relative;
}

.aspectWrapper {
  /* padding-bottom: calc(100% * (2 / 3));

  & video {
    height: 118px !important;
  } */
}

.skeleton {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}


.image {
  /* position: absolute; */
  height: 100%;
}

.message,
.ownMessage {
  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;
  max-width: 433px;
}

.message {
  display: flex;
  flex-direction: row;
}

.ownMessage {
  float: right;

  @media (max-width: 460px) {
    float: none;
  }
}

.avatar {
  flex-shrink: 0;
  margin: 0px 12px 0 0;

  @media (--viewportMedium) {
    margin: 0px 12px 0 0;
  }
}

.ownMessageContentWrapper {
  composes: clearfix from global;
}

.messageContent,
.ownMessageContent {
  composes: marketplaceMessageFontStyles from global;

  display: inline-block;
  margin: 0;
  padding: 8.5px 14px 8.5px 14px;
  border-radius: 8px;
  box-shadow: var(--boxShadow);

  @media (--viewportMedium) {
    padding: 8.5px 14px 8.5px 14px;
    margin: 0;
  }
}

.messageContent {
  flex: 1;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  letter-spacing: -0.0888889px;
  color: #4e5155;
}

.messageContentUrl {
  margin: 0px;
  padding: 0px;
  flex: 1;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  letter-spacing: -0.0888889px;
  color: #4e5155;
}

.ownMessageContent {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #6dabc8;
  color: var(--matterColorLight);
  float: right;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;

  @media (max-width: 768px) {
    width: 100%;
    word-wrap: break-word;
  }
}

.messageDate,
.ownMessageDate {
  composes: marketplaceMessageDateFontStyles from global;

  margin: 11px 0 0 0;
  color: var(--matterColorAnti);

  @media (--viewportMedium) {
    margin: 6px 0 0 0;
  }
}

.ownMessageDate {
  text-align: right;
}

.transitionItem {
  margin-bottom: 18px;

  /* Clearfix */
  composes: clearfix from global;

  @media (--viewportMedium) {
    margin-bottom: 16px;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}

.transition {
  display: flex;
  flex-direction: row;
  padding: 3px 0 2px 0;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.transitionContent {
  composes: marketplaceTxTransitionFontStyles from global;
  margin: 0;

  & span {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    /* identical to box height, or 200% */

    letter-spacing: -0.0888889px;

    /* TPG - Paragraph Text */

    color: #4e5155;
  }
}

.transitionDate {
  composes: marketplaceMessageDateFontStyles from global;
  color: var(--matterColorAnti);
  margin: 7px 0 0 0;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  /* identical to box height, or 200% */

  /* TPG - Disabled */

  color: #969697;

  @media (--viewportMedium) {
    margin: -1px 0 1px 0;
  }
}

.bullet {
  margin-right: 6px;
}

.reviewContent {
  composes: h4 from global;
  font-style: italic;
  white-space: pre-line;
  margin: 8px 0 0 0;

  @media (--viewportMedium) {
    max-width: 500px;
    margin: 7px 0 0 0;
  }
}

.reviewStars {
  display: block;
  margin-top: 4px;

  @media (--viewportMedium) {
    margin: 5px 0;
  }
}

.reviewStar {
  width: 12px;
  height: 12px;
  margin-right: 2px;
  font-styles: initial;
}

.showOlderWrapper {
  text-align: center;
  margin-bottom: 1px;

  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}

.showOlderButton {}

.filesWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  margin: 14px 0px;

  &>div {
    width: 165px;
    height: 165px;
    overflow: hidden;
    border-radius: 4px;
    background: #ddd;

    &>div {
      height: 100%;
    }

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    & video {
      width: 100%;
      height: 100%;
      /* object-fit: contain; */
    }
  }

}

.messageFirstLabel {
  color: #6C6D7A;
  font-family: Source Sans Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 125% */
  margin: 15px 0;
}

.messageLabel {
  color: #6C6D7A;
  font-family: Source Sans Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 125% */
  margin: 5px 0;
}

.messageItem {
  color: #1A1919;
  font-family: Source Serif Pro;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  /* 177.778% */
}

.leaveReview {
  & span {
    color: #f6861f;
    text-decoration: underline;
  }
}



.filesWrapper {
  display: flex;
  flex-wrap: wrap;
}

.threeToTwoWrapper {
  position: relative;
  /* margin-right: 10px;
  margin-bottom: 10px; */
  cursor: pointer;
}

.aspectWrapper {
  width: 118px;
  height: 118px;
  overflow: hidden;
  position: relative;
}

.aspectWrapper video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.imageBox {
  width: 100%;
  height: 118px;
  overflow: hidden;
  position: relative;
}

.imageBox img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.lightboxContent {
  object-fit: contain;
  /* min-width: 400px; */
  width: 500px;
  height: 500px;
}

.lightboxClose,
.lightboxPrev,
.lightboxNext {
  position: absolute;
  top: 15px;
  color: #fff;
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
}

.lightboxClose {
  right: 15px;
}

.lightboxPrev {
  left: 15px;
}

.lightboxNext {
  right: 15px;
}

.lightBoxButton {
  position: absolute;
  top: 50%;
  width: 100%;

  & button {
    background-color: #000;
    width: 40px;
    height: 40px;
    font-size: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.outerLightbox {
  position: relative;
}