.root {
}

.media {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 0;
}

.text {
  width: 100%;
  margin-top: 20px;

  &:first-child {
    margin-top: 0;
  }
}

.joinProButton{
  @media (min-width:768px) {
    display: none !important;
  }
}


:global(#hero-block) h1{
  max-width: 1156px;
  @media (max-width:767px) {
    font-size: 44px;
    font-style: normal;
    font-weight: 900;
    line-height: 55px; /* 125% */
    letter-spacing: -1.422px;
  }
}


:global(#hero)>div img{
  /* object-position: right !important ; */object-position: 92% 50% !important;
}

.buttonsGroup{
  display: flex;
  gap: 20px;
  @media (max-width:767px) {
 flex-direction: column;
 gap: 0px;
  }
}