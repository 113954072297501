@import '../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.name {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.firstNameRoot {
  width: calc(50% - 9px);
  & > div{
    margin-top: 10px;
    margin-bottom: 4px;
  }
}

.lastNameRoot {
  width: calc(50% - 9px);
  & > div{
    margin-top: 10px;
    margin-bottom: 4px;
  }
}

.password {
  composes: marketplaceModalPasswordMargins from global;
  & > div{
    margin-top: 10px;
    margin-bottom: 4px;
  }
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
  margin-top: 55px;
}

.bottomWrapperText {
  composes: marketplaceModalBottomWrapperText from global;
}

.termsText {
  composes: marketplaceModalHelperText from global;
}

.termsLink {
  composes: marketplaceModalHelperLink from global;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.signupInputs {
  & input {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    letter-spacing: -0.0888889px;
    color: #4A4A4A;
  }
}

.emailInput{
  & > div{
    margin-top: 12px;
    margin-bottom: 4px;
  }
}

label {
  font-weight: 600;
  font-size: 15px;
}


.checkbox{
  margin-top: 24px;
  font-weight: 600 !important;
  font-size: 15px !important;
}

.error {
  color: #FF0000;
}
