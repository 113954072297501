@import '../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.name {
  display: flex;
  justify-content: space-between;
  /* margin-top: 24px; */
  gap: 18px;

  @media (--viewportMedium) {
    /* margin-top: 32px; */
  }
}

.firstNameRoot {
  width: calc(50% - 9px);
}

.lastNameRoot {
  width: calc(50% - 9px);
}

.password {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
  margin-top: 38px;
}

.bottomWrapperText {
  composes: marketplaceModalBottomWrapperText from global;
  margin: 0;
  margin-bottom: 39px;
}

.termsText {
  composes: marketplaceModalHelperText from global;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #585858;

  & > span {
    &span {
      & > span {
        margin-left: 5px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        text-decoration: underline;
        line-height: 24px;
        font-family: 'Source Sans Pro';
        text-align: center;
        color: #4895ba;
      }
    }
  }
}

.servicesButton {
  margin-bottom: 22px;
}

.termsLink {
  composes: marketplaceModalHelperLink from global;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.root {
  margin-top: 0;
}

.sectionContainer {
  padding: 0;
  margin-bottom: 36px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 56px;
  }
}

.sectionTitle {
  /* Font */
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 12px;
  padding-top: 3px;
  padding-bottom: 3px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 24px;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.lastSection {
  margin-bottom: 69px;

  @media (--viewportMedium) {
    margin-bottom: 111px;

    & .sectionTitle {
      margin-bottom: 16px;
    }
  }
}

.uploadAvatarInput {
  display: none;
}

.uploadAvatarWrapper {
  margin-top: 0;
  margin-bottom: 30px;
}

.label {
  width: fit-content;
  margin: auto;
  /* width: var(--ProfileSettingsForm_avatarSize);

  @media (--viewportMedium) {
    width: var(--ProfileSettingsForm_avatarSizeDesktop);
  } */
}

.avatarPlaceholder,
.avatarContainer {
  /* Dimension */
  position: relative;
  width: 156px;
  height: 161px;
  margin: auto;
  /* Center content */
  display: flex;
  align-items: center;
  justify-content: center;

  /* Initial coloring */
  background-color: #d9d9d9;
  border-radius: 50%;
  cursor: pointer;

  @media (--viewportMedium) {
    /* width: var(--ProfileSettingsForm_avatarSizeDesktop);
    height: var(--ProfileSettingsForm_avatarSizeDesktop); */
    border-radius: 50%;
  }
}

.avatarPlaceholder {
  /* Placeholder border */
  /* border-style: dashed; */
  /* border-color: var(--matterColorNegative); */
  /* border-width: 2px; */
  border: 1px solid #969697;

  transition: var(--transitionStyleButton);
  & svg {
    & path {
      fill: #3c5b81;
    }
  }
  position: relative;
  & .changeButton {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: max-content;
    bottom: 0;
    border: 1px solid var(--attentionColor);
    background-color: #fff;
    padding: 4px 6px;
    font-size: 14px;
    border-radius: 4px;
    color: var(--attentionColor);
    font-weight: 600;
    & svg {
      & path {
        fill: var(--attentionColor) !important;
      }
    }
  }
  &:hover {
    border-color: var(--matterColorAnti);
  }
}

.avatarPlaceholderTextMobile {
  @media (--viewportMedium) {
    display: none;
  }
}

.avatarPlaceholderText {
  display: none;

  @media (--viewportMedium) {
    display: block;
    max-width: 130px;
    text-align: center;
  }
}

.avatarUploadError {
  /* Placeholder border */
  border-style: dashed;
  border-color: var(--failColor);
  border-width: 2px;
}

.diffTypes {
  margin-bottom: 32px;
}

.error {
  /* Font */
  composes: h4 from global;
  color: var(--failColor);
  margin-top: 18px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 22px;
    margin-bottom: 2px;
  }
}

.avatar {
  width: 100%;
  height: 100%;
}

.changeAvatar {
  /* Font */
  composes: h5 from global;
  font-weight: var(--fontWeightMedium);

  /* Positioning: right */
  position: absolute;
  bottom: 27px;
  right: -129px;
  /* Dimensions */
  width: 105px;

  padding: 11px 10px 7px 35px;

  /* Look and feel (buttonish) */
  background-color: var(--matterColorLight);
  /* background-image: url('data:image/svg+xml;utf8,<svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"><g stroke="%234A4A4A" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><path d="M5.307 11.155L1 13l1.846-4.308L10.54 1 13 3.46zM11 5L9 3M5 11L3 9"/></g></svg>');
  background-repeat: no-repeat;
  background-position: 15px 12px;
  border: solid 1px var(--matterColorNegative); */
  border-radius: 2px;

  margin-top: 0;
  margin-bottom: 0;
  border: 1px solid var(--attentionColor);
  background-color: #fff;
  padding: 4px 6px;
  font-size: 14px;
  border-radius: 4px;
  color: var(--attentionColor);
  font-weight: 600;
  & svg {
    & path {
      fill: var(--attentionColor) !important;
    }
  }
  @media (--viewportMedium) {
    /* Position: under */
    bottom: -10px;
    right: auto;
    margin-top: 0;
    margin-bottom: 0;
    transition: var(--transitionStyleButton);
    padding: 4px 6px;
  }

  &:hover {
    border: solid 1px var(--matterColorAnti);
  }

  @media (max-width: 768px) {
    bottom: 0;
    right: 0;
  }
}

.uploadingImage {
  /* Dimensions */
  width: 156px;
  height: 156px;
  min-width: 156px;
  min-height: 156px;

  /* Image fitted to container */
  object-fit: cover;
  background-color: var(--matterColorNegative);
  /* Loading BG color */
  border-radius: 50%;
  overflow: hidden;

  display: block;
  position: relative;
  margin: 0;

  @media (--viewportMedium) {
    width: 156px;
    height: 156px;
    min-width: 156px;
    min-height: 156px;
    border-radius: 50%;
  }
}

.uploadingImageOverlay {
  /* Cover everything (overlay) */
  position: absolute;
  top: 0;
  right: 0;
  /* bottom: 0; */
  left: 0;

  /* Overlay style */
  background-color: var(--matterColorLight);
  opacity: 0.8;

  /* Center content */
  display: flex;
  justify-content: center;
  align-items: center;

  /* width: 100%; */
  height: 100%;
  border-radius: inherit;
}

/* Avatar has square aspect ratio */
/* Default is 3:2 */
.squareAspectRatio {
  padding-bottom: 100%;
}

.avatarInvisible {
  visibility: hidden;
  position: absolute;
  top: -1000px;
  left: -1000px;
}

.tip {
  composes: marketplaceDefaultFontStyles from global;
  color: var(--matterColorAnti);
  margin-top: 0;
  margin-bottom: 12px;
  padding-top: 5px;
  padding-bottom: 1px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 8px;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.fileInfo {
  composes: h5 from global;
  color: var(--matterColorAnti);
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 5px;
  padding-bottom: 1px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 6px;
    padding-bottom: 2px;
  }
}

.nameContainer {
  display: flex;
  justify-content: space-between;
  /* margin-top: 12px; */
  margin-bottom: 32px;

  @media (--viewportMedium) {
    /* margin-top: 24px; */
  }
}

.emailInput {
  margin-bottom: 32px;
}

.genderSelect {
  width: calc(50% - 9px);
}

.firstName {
  width: calc(34% - 9px);
}

.lastName {
  width: calc(66% - 9px);
}

.bioInfo {
  color: var(--matterColorAnti);
  margin-top: 11px;

  @media (--viewportMedium) {
    margin-top: 16px;
  }
}

.submitButton {
  margin-top: 24px;
}

.locationAddress,
.building {
  flex-shrink: 0;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.locationAutocompleteInput {
  flex-grow: 1;
  height: 36px;
  line-height: unset;
  padding-left: 0;
  margin: 0;
  border-bottom: 2px solid var(--attentionColor);
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  /* identical to box height, or 200% */

  letter-spacing: -0.0888889px;

  color: #4a4a4a;

  &:hover,
  &:focus {
    border-bottom-color: var(--matterColorDark);
    outline: none;
  }

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  @media (--viewportMedium) {
    height: 40px;
  }
}

.locationAutocompleteInputIcon {
  display: none;
}

.predictionsRoot {
  position: absolute;
  width: 100%;
  padding-bottom: var(--locationAutocompleteBottomPadding);
  top: 36px;
  left: 0;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);

  @media (--viewportMedium) {
    top: 40px;
  }
}

.validLocation {
  border-bottom-color: var(--successColor);
}

.diffrentTypeLabel {
  display: block;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px !important;
  color: #4a4a4a;
  margin-top: 0;
  margin-bottom: 5px;
}

.diffrentTypeBox {
  margin-bottom: 32px;
}

.disable {
  margin-bottom: 22px;
  cursor: not-allowed;
  opacity: 0.3;
}

.cropperModal {
  /* width: 100px;
height: 100px; */
}

.countryField {
  display: flex;
  gap: 12px;
  margin-bottom: 32px;
}
.countrySelect {
  width: calc(50% - 9px);
  position: relative;
  & > div:last-child {
    position: absolute;
    top: 100%;
    left: 0;
    margin-top: 0;
    font-size: 12px;
  }
  & select {
    @media (--viewportMedium) {
      padding: 0;
    }
  }
}

.PhoneInput {
  width: calc(50% - 9px);
  position: relative;
  & > div:last-child {
    position: absolute;
    top: 100%;
    left: 0;
    margin-top: 0;
    font-size: 12px;
  }
}