@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
}

.bookingDates {
  flex-shrink: 0;
  margin-bottom: 36px;

  /* Ensure that calendar dropdown gets some stacking context relative to other form items below */
  z-index: 1;
}

.priceBreakdownContainer {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 0;
    margin-top: 0;
  }
}

.priceBreakdownTitle {
  /* Font */
  color: var(--matterColorAnti);

  margin-bottom: 14px;
  margin-top: 0;
  font-family: 'Source Serif Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.1125px;
  color: #8f908d;

  @media (--viewportMedium) {
    margin-bottom: 18px;
  }
}

.receipt {
  flex-shrink: 0;
  margin: 0 0 24px 0;
}

.error {
  color: var(--failColor);
  margin: 0 24px;
  display: inline-block;
}

.spinner {
  margin: auto;
}

.sideBarError {
  composes: h4 from global;
  color: var(--failColor);
  margin: 0 24px 12px 24px;

  @media (--viewportMedium) {
    margin: 0 0 12px 0;
  }
}

.smallPrint {
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColorAnti);
  text-align: center;
  margin: auto 24px 20px 24px;
  flex-shrink: 0;

  @media (--viewportMedium) {
    margin-top: auto;
    margin-bottom: 20px;
  }

  @media (--viewportLarge) {
    margin-top: 4px;
    margin-bottom: 21px;
  }
}

.submitButtonWrapper {
  flex-shrink: 0;
  padding: 0 24px 24px 24px;
  width: 100%;

  @media (--viewportMedium) {
    padding: 0;
    width: 100%;
  }
}

.timeChangedText {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 22px;
  color: #000000;
  margin-top: 3px;
  margin-left: 12px;
  @media (max-width: 768px) {
    margin-bottom: 30px;
  }
  & a {
    margin-left: 5px;
    color: #000000;
    text-decoration: underline;
  }
}

.listingSmallText {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  text-align: center;
  color: #949494;
  margin: 0 0 15px 0;
}

.bottomButtonWrapper {
  padding: 20px 0 30px;
}

.informationIcon {
  position: relative;
  display: inline-block;
  margin-right: 10px;
  
  &:hover {
    & .hoverText {
      visibility: visible;
    }
  }

  & .hoverText {
    visibility: hidden;
    width: 382px;
    background: #4f8a7f;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    border-radius: 10px;
    color: #fff;
    padding: 18px;
    position: absolute;
    z-index: 1;
    top: -40px;
    right: 200%;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #ffffff;
    &.hoverText2{
      right: 140%;
    }
    @media (max-width: 1023px) {
      bottom: 100%;
      top: unset;
      left: 0;
      right: unset !important;
      &.hoverText2{
        left: 0 ;
        right: unset;
      }
    }
    @media (max-width: 430px) {
      max-width: 300px;
    }

    /* &:after {
      content: '';
      position: absolute;
      top: 66%;
      left: 99%;
      margin-top: -16px;
      border-width: 15px;
      border-style: solid;
      border-color: transparent transparent transparent #4f8a7f;
    } */
  }
}

.informationIcon2{
  @media (max-width: 1023px) {
    display: block !important;
  }
}



.contactWrapper {
  /* display: none; */

  @media (--viewportMedium) {
    display: inline;
  }
}

.shareButton {
  margin-right: 13px;
  & button {
    background: #e5f2f3;
    border: 1.5px solid #40526e;
    border-radius: 100px;
    padding: 11px 22px;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #40526e;
    display: flex;
    & svg {
      margin-right: 10px;
    }
  }
}

.separator {
  margin: 0 6px;
}

.contactLink {
  /* Position and dimensions */
  display: inline;
  padding: 0;

  /* Borders */
  border: none;

  /* Colors */
  color: var(--marketplaceColor);

  /* Text size should be inherited */
  text-decoration: none;

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  margin: 0;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    line-height: 16px;
  }
}

.sectionDescription {
  padding: 0 24px;
  margin-bottom: 35px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 36px;
  }
}
.avlLocalHire {
  background: #e5f2f3;
  border: 1.5px solid #40526e;
  border-radius: 100px;
  padding: 11px 22px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: #40526e;
  position: relative;
  width: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  /* width: -webkit-fill-available; */

  & span {
    margin-right: 8px;
  }
  & .author {
    position: absolute;
    top: 100%;
    display: none;
    margin-top: 0;
    left: 0;
    right: 0;
    min-width: 150px;
    width: 70%;
    margin: 0 auto;
    height: auto;
    padding-top: 10px;
    & .contactWrapper {
      text-align: center;
      margin: 0;
      border-radius: 10px;
      display: block;
      /* max-height: 100px; */
      background-color: #3c5b81;
      text-align: center;
      color: white;
      font-size: 16px;
      line-height: 20px;
      padding: 16px;
      font-family: Arial, Helvetica, sans-serif;
      &:after {
        content: '';
        display: block;
        height: 10px;
        width: 10px;
        background-color: #3c5b81;
        position: absolute;
        top: 5px;
        right: 50%;
        transform: rotate(45deg);
      }
    }
    & span {
      color: #fff;
    }
  }
  &:hover {
    & .author {
      position: absolute;
      top: 100%;
      display: block;
    }
  }
}

.desktopPriceContainer {
  display: none;

  @media (--viewportLarge) {
    /* Layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    padding: 0;
  }
}
.helpText {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 21px;
  color: #000000;
  margin-top: 14px;
  margin-left: 12px;
  text-align: left;
}