@import '../../styles/customMediaQueries.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.priceInput {
  flex-shrink: 0;
  width: 100%;
}

.submitButton {
  margin-top: 40px;
  margin-bottom: 24px;
  width: 241px;

  @media (--viewportLarge) {
    display: inline-block;

    margin-top: 35px;
  }

  @media(max-width: 768px) {
    margin-bottom: 50px;
  }

  @media(max-width: 460px) {
    width: 100%;
  }
}

.subTitleName {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #40526E;

  @media(max-width: 768px) {
   font-size: 16px;
   line-height: 20px; 
  }
}

.insuranceCheck {
  margin-top: 42px;

  & .rowCheckBox {
    & label {
      & span {
        font-family: 'Source Serif Pro';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.01em;
        color: #000000;
      }
    }
  }
}

.insureInput {
  margin-top: 37px;
}